import { useRef, useState } from 'react';
import { components } from '@features/game/api';
import { components as components2 } from '@features/shared/api/club/generated';
import { useTournamentGamesSocket } from '@features/shared/api/streams';
import { SquareName } from '@libs/chess/types';
import { useImmediateEffect } from '@libs/hooks/useImmediateEffect';
import {
  TournamentGamesGame,
  TournamentGamesProps,
} from '../ui/components/TournamentGames';

// FIXME: перевести всё на простые RoundSocket + Mapping (discard и enabled не нужен)

/*
    ____,-------------------------------,____
    \   |             Hook              |   /
    /___|-------------------------------|___\
*/

type Value = {
  loading: boolean;
  error?: Error | null;
  games?: TournamentGamesProps['games'];
  cachedGames?: TournamentGamesProps['games'];
};

export function useTournamentGames(
  tournamentId: string,
  // HACK: тут должен был быть roundNumber, но передаётся весь раунд, чтобы
  // по статусу раунда определить, когда сокет можно отключить сокет (по статусу игр
  // этого теперь сделать невозможно, т.к. сейчас доп. партии создаются в
  // тот же раунд, а не в новый, как в приличном обществе)
  round?: components2['schemas']['RoundDto'],
  { enabled } = { enabled: true },
): Value {
  const [games, setGames] = useState<TournamentGamesGame[] | undefined>();
  const storeRef = useRef<Store | undefined>();
  const roundNumber = round?.roundNumber || NaN;

  // сбросить стор при смене параметров
  useImmediateEffect(() => {
    storeRef.current = undefined;
  }, [tournamentId, roundNumber]);

  useTournamentGamesSocket({
    tournamentId,
    roundNumber,
    cb(e) {
      switch (e.eventType) {
        case 'RoundGamesState': {
          const store = new Store(e.data);
          storeRef.current = store;
          setGames(store.games);
          break;
        }
        case 'GameChanged': {
          if (!storeRef.current) return;
          storeRef.current.update(e.currentState);
          setGames(storeRef.current.games);
          break;
        }
      }
    },
    // TODO: вернуть, если доп. партии перенесём в отдельный раунд и можно
    // будет закрывать сокет, если все игры закончились
    // enabled: enabled && (!storeRef.current || storeRef.current.isLive)
    enabled: Boolean(
      round && enabled && (!storeRef.current || round?.status !== 'Finished'),
    ),
  });

  return {
    loading: !storeRef.current,
    games: storeRef.current ? games : undefined,
    cachedGames: games,
  };
}

/*
    ____,-------------------------------,____
    \   |             Store             |   /
    /___|-------------------------------|___\
*/

class Store {
  #dtos: Record<string, components['schemas']['GameDto']> = {};
  #games: Record<string, TournamentGamesGame> = {};

  constructor(data: components['schemas']['GameDto'][]) {
    data
      // FIXME: HACK: пересортировываем партии по дате начала вместо номера
      // доски (ибо пока в раунд могут добавится дополнительные партии на тех же
      // досках). Думаем: а не стоит ли заводить под доп. партии отдельные раунды.
      .map((d) => ({ ...d, toString: () => d.startTimestampMs }))
      .sort()
      .forEach((g) => {
        this.update(g);
      });
  }

  get games(): TournamentGamesGame[] {
    return Object.values(this.#games);
  }

  get isLive() {
    return Boolean(
      Object.values(this.#dtos).find((g) => g.status !== 'Finished'),
    );
  }

  update(g: components['schemas']['GameDto']) {
    this.#dtos[g.id] = g;
    this.#games[g.id] = this.#mapGame(g);
  }

  #mapGame(g: components['schemas']['GameDto']): TournamentGamesGame {
    return {
      id: g.id,
      black: this.#mapPlayer(g.black, g.clock.blackLeftMs),
      white: this.#mapPlayer(g.white, g.clock.whiteLeftMs),
      fen: g.currentFen,
      lastMove: this.#mapLastMove(g.moves[g.moves.length - 1]),
      winner: this.#mapWinner(g.result),
      ticking: this.#mapTicking(g),
    };
  }

  #mapWinner(
    result: components['schemas']['GameResult'],
  ): TournamentGamesGame['winner'] {
    if (result === 'BlackWin') return 'black';
    if (result === 'WhiteWin') return 'white';
    if (result === 'Draw') return 'draw';
    return undefined;
  }

  #mapPlayer(
    player: components['schemas']['PlayerDto'],
    timeMs: number,
  ): TournamentGamesGame['black'] {
    return {
      name: `${player.lastName} ${player.firstName}`,
      time: Math.ceil(timeMs / 1000),
    };
  }

  #mapLastMove(
    move?: components['schemas']['MoveDto'],
  ): TournamentGamesGame['lastMove'] {
    if (!move) return undefined;

    return {
      from: move.from as SquareName,
      to: move.to as SquareName,
    };
  }

  #mapTicking(
    g: components['schemas']['GameDto'],
  ): TournamentGamesGame['ticking'] {
    return g.result !== 'InProgress'
      ? undefined
      : g.nextMoveBy === 'White'
        ? {
            color: 'white',
            timeMs: g.clock.whiteLeftMs,
            timeFromMs: g.timestampMs,
          }
        : {
            color: 'black',
            timeMs: g.clock.blackLeftMs,
            timeFromMs: g.timestampMs,
          };
  }
}
