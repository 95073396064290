import styles from './Fraction.module.css';

export type Props = {
  value?: string;
};

const FLOAT_FRACTIONS_MAP = {
  '0.1': ['1', '10'],
  '0.2': ['1', '5'],
  '0.25': ['1', '4'],
  '0.3': ['1', '3'],
  '0.4': ['2', '5'],
  '0.5': ['1', '2'],
  '0.6': ['2', '3'],
  '0.7': ['7', '10'],
  '0.75': ['3', '4'],
  '0.8': ['4', '5'],
  '0.9': ['9', '10'],
};

export function Fraction({ value }: Props) {
  if (!value) return value;
  if (value === '0') return value;

  let integer;

  // Input with a slash, like '1/32'
  let [numerator, denominator] = value.split('/');

  if (!numerator || !denominator) {
    // Input without a slash, like '1.5';
    if (value.indexOf('.') === -1) return value;

    const numeric = Number(value);
    integer = Math.floor(numeric) || '';

    numerator = '1';
    const floatNumber = numeric % 1;

    if (floatNumber in FLOAT_FRACTIONS_MAP) {
      [numerator, denominator] =
        FLOAT_FRACTIONS_MAP[
          floatNumber.toString() as keyof typeof FLOAT_FRACTIONS_MAP
        ];
    } else {
      return value;
    }
  }

  return (
    <span>
      {integer && <span className={styles.integer}>{integer}</span>}
      <span className={styles.fraction}>
        <span className={styles.numerator}>{numerator}</span>
        <span className={styles.divider}>⁄</span>
        <span className={styles.denominator}>{denominator}</span>
      </span>
    </span>
  );
}
