import { EMPTY_POSITIONS, SQUARES } from '@libs/chess/constants';
import { BoardPositions, PieceCode, SquareName } from '@libs/chess/types';

export type BoardPositionsIds = {
  squares: Partial<Record<SquareName, string>>;
  ids: Partial<Record<string, SquareName | null>>;
  promotions?: string[];
};

export function trackIds(
  prev: BoardPositions | null,
  next: BoardPositions | null,
  prevIds: BoardPositionsIds = createIds(next ?? EMPTY_POSITIONS),
): BoardPositionsIds {
  if (!prev && !next) {
    return prevIds;
  }

  if (!prev) {
    return createIds(next!);
  }

  if (!next) {
    return createIds(EMPTY_POSITIONS);
  }

  const nextIds: BoardPositionsIds = {
    // ВАЖНО! Для анимаций и react reconciler'а необходимо, чтобы порядок id не
    // менялся, поэтому переносим старые ключи в новый реестр.
    ids: Object.keys(prevIds.ids).reduce(
      (sink, k) => {
        sink[k] = null;
        return sink;
      },
      {} as BoardPositionsIds['ids'],
    ),
    squares: {},
  };

  // Ищем изменения

  const found: SquareName[] = [];
  const lost: SquareName[] = [];

  SQUARES.forEach((s) => {
    const before: PieceCode | undefined = prev[s];
    const after: PieceCode | undefined = next[s];

    if (before === after || (!before && !after)) {
      // 1. состояние клетки не поменялось
      const id = prevIds.squares[s];
      if (id) {
        nextIds.ids[id] = s;
        nextIds.squares[s] = id;
      }
    } else if (!before) {
      // 2. фигура на новом месте
      found.push(s);
    } else if (!after) {
      // 3. фигуры нет на старом месте
      lost.push(s);
    } else {
      // 4. в клетке поменялась фигура
      lost.push(s);
      found.push(s);
    }
  });

  // Интерпретируем изменения

  const newPieces: SquareName[] = [];
  const movedPieces: [SquareName, SquareName][] = [];

  found.forEach((s) => {
    const indexBefore = findClosestSquareIndex(s, next[s]!, lost, prev);
    if (indexBefore !== -1) {
      const squareBefore = lost[indexBefore]!;
      movedPieces.push([squareBefore, s]);
      lost.splice(indexBefore, 1);
    } else {
      newPieces.push(s);
    }
  });

  // Синхронизируем ключи

  const promotablePawns = lost.filter((s) => {
    return (
      (s[1] === '7' && prev[s] === 'wP') || (s[1] === '2' && prev[s] === 'bP')
    );
  });
  const promotedPawns = newPieces.filter((s) => {
    if (!promotablePawns.length) return false;

    const piece = next[s]!;
    const canBePromoted = 'QNRB'.includes(piece[1]!);

    if (!canBePromoted) return false;

    const color = piece[0] as 'w' | 'b';
    const coords = getCoords(s);
    const isCapture = prev[s] && prev[s][0] !== color;

    const pawnSquare = promotablePawns.find((ps) => {
      const pawn = prev[ps]!;
      if (pawn[0] !== color) return false;

      const pawnCoords = getCoords(ps);
      return isCapture
        ? pawnCoords[0] === coords[0] - 1 || pawnCoords[0] === coords[0] + 1
        : pawnCoords[0] === coords[0];
    });

    // переносим в передвинутые фигуры
    if (pawnSquare) movedPieces.push([pawnSquare, s]);

    return !!pawnSquare;
  });
  newPieces
    // игнорируем превращённые пешки, т.к. перенесли их в передвинутые фигуры ранее
    .filter((s) => !promotedPawns.includes(s))
    .forEach((s) => {
      const id = `${s}-${Math.random()}`;
      nextIds.ids[id] = s;
      nextIds.squares[s] = id;
    });
  movedPieces.forEach(([f, t]) => {
    const id = prevIds.squares[f]!;
    nextIds.ids[id] = t;
    nextIds.squares[t] = id;
  });

  if (promotedPawns.length) {
    nextIds.promotions = promotedPawns.map((s) => nextIds.squares[s]!);
  }

  return nextIds;
}

function createIds(pos: BoardPositions): BoardPositionsIds {
  const ids: BoardPositionsIds['ids'] = {};
  const prefix = Math.random();

  const squares = (Object.keys(pos) as SquareName[]).reduce(
    (sink, s) => {
      const id = `${s}-${prefix}`;
      sink[s] = id;
      ids[id] = s;
      return sink;
    },
    {} as BoardPositionsIds['squares'],
  );

  return { squares, ids };
}

function findClosestSquareIndex(
  square: SquareName,
  piece: PieceCode,
  lost: SquareName[],
  positions: BoardPositions,
): number {
  const point = getCoords(square);
  const options = lost.map((s, i) => ({
    i,
    piece: positions[s],
    distance: calcDistance(point, getCoords(s)),
  }));
  const found = options
    .filter((o) => o.piece === piece)
    .sort((o1, o2) => o1.distance - o2.distance)[0];

  return found?.i ?? -1;
}

// Перевести строку и столбц клетки в координты {x; y} для обсчёта дистанций
function getCoords(square: SquareName): [x: number, y: number] {
  const file = square.charCodeAt(0) - 97; // a-h -> 0-7
  const rank = square.charCodeAt(1) - 49; // 1-8 -> 0-7
  return [file, rank];
}

// Расстояние между двумя точками
function calcDistance(
  pointA: [x: number, y: number],
  pointB: [x: number, y: number],
): number {
  const dx = pointA[0] - pointB[0];
  const dy = pointA[1] - pointB[1];
  return dx * dx + dy * dy;
}
