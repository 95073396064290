const THRESHOLD_SOCKET_MS = 100;
const THRESHOLD_NTP_MS = 100;

/**
 *  Синхронизация времени между клиентом и сервером.
 *
 *  P.S. Если у клиента высокое latency — то в принципе всё пропало (не только часы).
 *  P.P.S. Если клиент переведёт часы — беда
 *  P.P.P.S. Високосные секунды не поддерживаются
 */
export class TimeSyncService {
  #deltaUnset = true;
  #deltaSocket = 0;
  #ntpUnset = true;
  #offsetNtp = 0;

  get #delta() {
    if (this.#ntpUnset) return this.#deltaSocket;
    if (this.#deltaUnset) return this.#offsetNtp;

    // ntp offset не может быть больше socket delta
    return Math.min(this.#deltaSocket, this.#offsetNtp);
  }
  private constructor() {}

  /**
   * Если нужно серверный timestamp превратить в локальный.
   *
   * Например, чтобы показывать тикающий таймер обратного отсчёта, который бы
   * совпадал с серверным.
   */
  toClientTimestamp(serverTs: null): null;
  toClientTimestamp(serverTs: undefined): undefined;
  toClientTimestamp(serverTs: number): number;
  toClientTimestamp(
    serverTs: number | null | undefined,
  ): number | null | undefined;
  toClientTimestamp(serverTs?: number | null) {
    if (serverTs === null || serverTs === undefined) return serverTs;
    return serverTs + this.#delta;
  }

  /**
   * Если нужно локальный timestamp превратить в серверный
   *
   * Например, если нужно показать часы с серверным временем
   */
  toServerTimestamp(clientTs: null): null;
  toServerTimestamp(clientTs: undefined): undefined;
  toServerTimestamp(clientTs: number): number;
  toServerTimestamp(
    clientTs: number | null | undefined,
  ): number | null | undefined;
  toServerTimestamp(clientTs?: number | null) {
    if (clientTs === null || clientTs === undefined) return clientTs;
    return clientTs - this.#delta;
  }

  /**
   *  Синхронизация по timestamp сообщений сокета
   *
   *  Если время на клиенте > серверного, то причиной может быть сетевое latency.
   *  Если время на клиенте < сервеного — это всегда неверное время.
   *  Чем меньше разница клиент-сервер, тем меньше участие latency.
   *
   *  а) Клиентские часы отстают на 00:05
   *
   *        12:30
   *          v
   *     s: --|-------    (минимальное latency)
   *     c: ---|------
   *           ^
   *         12:25 (-5)
   *
   *        12:30
   *          v
   *     s: --|-------
   *     c: -------|--
   *               ^
   *             12:30 (+0)
   *
   *        12:30
   *          v
   *     s: --|-------
   *     c: ---------|
   *                 ^
   *               12:35 (+5)
   *
   *  б) Клиентские часы спешат на 00:05
   *
   *        12:30
   *          v
   *     s: --|-------    (минимальное latency)
   *     c: ---|------
   *           ^
   *         12:35 (+5)
   *
   *        12:30
   *          v
   *     s: --|-------
   *     c: -------|--
   *               ^
   *             12:40 (+10)
   *
   *         12:30
   *          v
   *     s: --|-------
   *     c: ---------|
   *                 ^
   *               12:45 (+15)
   */
  reportSocketTs(timestamp: number) {
    const now = Date.now();
    const delta = now - timestamp;

    if (this.#deltaUnset && delta > 0 && delta < THRESHOLD_SOCKET_MS) return;

    // инициализация
    if (this.#deltaUnset) {
      // игнорируем небольшие расхождения, связанные с latency
      if (delta > 0 && delta < THRESHOLD_SOCKET_MS) return;
      this.#deltaUnset = false;
      this.#deltaSocket = delta;
    }

    // обновление
    if (delta < this.#delta) {
      this.#deltaSocket = delta;
    }
  }

  /**
   * Синхронизация по NTP алгоритму
   *
   */
  reportNtp(
    timestampClient1: number,
    timestampClient2: number,
    timestampServer: number,
  ) {
    // (s - c1 + (s - c2)) / 2 === (2s - (c1 + c2)) / 2
    const offset =
      (2 * timestampServer - (timestampClient1 + timestampClient2)) / 2;

    if (Math.abs(offset) < THRESHOLD_NTP_MS) return;

    // sic! сохраняем с обратным знаком (т.е. как отклонение сервера), для
    // единообразия с deltaSocket
    this.#offsetNtp = -offset;
    this.#ntpUnset = false;
  }

  // Statics

  static #instance: TimeSyncService;

  static get instance(): TimeSyncService {
    if (!this.#instance) {
      this.#instance = new TimeSyncService();
    }

    return this.#instance;
  }
}

/*

function ntp(c1: number, s: number, c2: number) {
  const offset = (s - c1 + (s - c2)) / 2;
  return -offset;
}
function socket(s: number, c2: number) {
  return c2 - s;
}

function testSkew(skew: number, maxLatency = 100) {
  const lat = () => Math.round(maxLatency * Math.random());
  const c1 = Date.now();
  const l1 = lat();
  const l2 = lat();
  const ls = lat();

  const resultNtp = (() => {
    const s = c1 + l1 - skew;
    const c2 = s + l2 + skew;
    return ntp(c1, s, c2);
  })();
  const resultSocket = (() => {
    const s = c1 - skew;
    const c2 = c1 + ls;
    return socket(s, c2);
  })();

  console.log(`Test skew ${skew}`);
  console.log('> ntp:', resultNtp, { l1, l2 });
  console.log('> socket:', resultSocket, { ls });

  return Math.min(resultNtp, resultSocket);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).test = testSkew;

*/
