import { useEffect, useState } from 'react';
import { useRouter } from '@tanstack/react-router';

/*
    ____,-------------------------------,____
    \   |              Hook             |   /
    /___|-------------------------------|___\
*/

const INTERVAL = 2.5 * 60 * 1000;
const URL = '/';

export function useDeployDetector() {
  const version = document.body.dataset['version'];
  const [needRefresh, setNeedRefresh] = useState(false);
  const router = useRouter();

  // Поллинг изменений
  useEffect(() => {
    if (!version) {
      console.error(
        'useDeployDetector: Please, set app version in <body> data-version attribute',
      );
      return;
    }

    if (needRefresh) return;

    const probe = async () => {
      fetch(URL)
        .then((r) => r.text())
        .then((html) => {
          // если есть новая версия
          if (html.indexOf(`data-version="${version}"`) === -1) {
            setNeedRefresh(true);
          } else {
            t = setTimeout(probe, INTERVAL);
          }
        });
    };
    let t = setTimeout(probe, INTERVAL);

    return () => clearTimeout(t);
  }, [needRefresh, version]);

  // Стратегия 1: Рефреш, если таба не в фокусе
  useEffect(() => {
    if (!needRefresh) return;

    if (document.visibilityState === 'hidden') {
      window.location.reload();
    } else {
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
          window.location.reload();
        }
      });
    }
  }, [needRefresh]);

  // Стратегия 2: Обновление страницы при следующей попытке навигации
  useEffect(() => {
    if (!needRefresh) return;

    router.subscribe('onBeforeNavigate', (e) => {
      // переход в обход роутера
      window.location.assign(e.toLocation.href);
    });
  }, [needRefresh, router]);
}
