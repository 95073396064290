import { cx } from '@libs/classnames';
import styles from './CollapseIcon.module.css';

export type Props = {
  className?: string;
  onClick?: () => void;
  toggled?: boolean;
};

export function CollapseIcon({ className, onClick, toggled }: Props) {
  return (
    <div
      onClick={onClick}
      className={cx(
        styles.collapseIcon,
        {
          [styles.isToggled]: toggled,
        },
        className,
      )}
    >
      &#9650;
    </div>
  );
}
