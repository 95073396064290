import { ChessBoardProps } from 'exports/ChessBoard';
import { Scoresheet } from 'exports/Scoresheet';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { components } from '@features/game/api';
import { useGameServer } from '@features/game/hooks/useGameServer';
import { useGameSubscription } from '@features/game/hooks/useGameSubscription';
import { useScoresheet } from '@features/game/hooks/useScoresheet';
import { DrawOffer } from '@features/game/pages/GamePage/components/DrawOffer';
import { GameInfo } from '@features/game/pages/GamePage/components/GameInfo/GameInfo';
import {
  GameLayout,
  GameLayoutSkeleton,
} from '@features/game/pages/GamePage/components/GameLayout/GameLayout';
import {
  PlayerPanelWidget,
  PlayerPanelWidgetProps,
} from '@features/game/pages/GamePage/components/PlayerPanel';
import {
  ButtonDraw,
  ButtonLeave,
  ButtonResign,
} from '@features/game/ui/GameButtons/GameButtons';
import { MatchCountdownWidget } from '@features/game/ui/MatchCountdown';
import { NetworkProblems } from '@features/game/ui/NetworkProblems/NetworkProblems';
import { PromotionPiece } from '@libs/chess/types';
import { OBJECT_EMPTY } from '@libs/constants';
import { useMediaQuery } from '@ui/components/MediaQueryProvider';
import { Toast } from '@ui/components/Toast';
import { LangResources, useTranslate } from '@ui/components/TranslateProvider';

/*
    ____,-------------------------------,____
    \   |              Page             |   /
    /___|-------------------------------|___\
*/

const TIME_PRESSURE = 10;

type Props = {
  key: string /* sic! Для разных игр необходимы разные ключи */;
  gameId: string;
  playerId?: string;
  accessKey?: string;
  customSocketUrl?: string;
  avatar?: ReactNode;
  showSkeleton?: boolean;
  autoPromotion?: PromotionPiece;
  customModal?: ReactNode;
  onLeave?: () => void;
};

export function GamePage({
  gameId,
  playerId,
  accessKey,
  customSocketUrl,
  avatar,
  showSkeleton,
  autoPromotion,
  customModal,
  onLeave,
}: Props) {
  const { lang, t } = useTranslate();
  const mobile = useMediaQuery() !== 'desktop';
  const { toast, setToast, clearToast } = useToast();
  const handleError = useCallback(
    (error: Error) => {
      setToast(
        <>
          <b>{t('features/game/pages/GamePage/Error Header')}</b>.{' '}
          {t('features/game/pages/GamePage/Error Text')}
        </>,
      );
      console.error(error);
    },
    [setToast, t],
  );

  const subscription = useGameSubscription({
    gameId,
    customSocketUrl,
    enabled: !showSkeleton,
  });

  const {
    loading,
    networkPromlems,
    //
    propsBoard,
    //
    game,
    player,
    //
    showDrawOffer,
    //
    handleOfferDraw,
    handleAcceptDraw,
    handleDeclineDraw,
    handleResign,
  } = useGameServer({
    gameId,
    playerId,
    accessKey,
    autoPromotion,
    subscription,
    onError: handleError,
  });

  const inProgress = game?.result === 'InProgress';
  const isPlayer = game?.white.id === playerId || game?.black.id === playerId;

  const title = formatTitle(game, t);
  const gameSpecs = useMemo(
    () => formatSpecs(game, propsBoard.orientation, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [game?.id, propsBoard.orientation, lang],
  );

  const scoresheet = useScoresheet(game, propsBoard);
  const customPlayersTimeMs =
    !scoresheet.isLastMove || game?.status === 'Finished'
      ? scoresheet.playersTimeMs
      : undefined;

  // FIXME: добавить обработку ошибок
  if (showSkeleton || loading || !game) return <GameLayoutSkeleton />;

  const modal = (function () {
    if (customModal) return customModal;
    if (networkPromlems) return <NetworkProblems />;
    if (game?.result === 'NotStarted') {
      return <MatchCountdownWidget game={game} />;
    }
  })();

  const propsBoardFinal: ChessBoardProps = Object.assign(
    propsBoard,
    scoresheet.propsPatch ?? OBJECT_EMPTY,
    modal ? { modal } : OBJECT_EMPTY,
  );

  const { orientation: play, winner: clientWinner } = propsBoard;
  const turn: PlayerPanelWidgetProps['turn'] = inProgress
    ? game.nextMoveBy === 'Black'
      ? 'black'
      : 'white'
    : null;
  const winner = mapWinner(game.result, clientWinner);

  const fullTurnHappened = game.moves.length >= 2;

  return (
    <GameLayout
      board={propsBoardFinal}
      avatar={avatar}
      header={<GameInfo title={title} specs={gameSpecs} />}
      errorToast={
        toast ? (
          <Toast type="error" onClose={clearToast}>
            {toast}
          </Toast>
        ) : null
      }
      popup={
        showDrawOffer && isPlayer ? (
          <DrawOffer
            onAccept={handleAcceptDraw}
            onDecline={handleDeclineDraw}
          />
        ) : undefined
      }
      player1={
        <PlayerPanelWidget
          orientation="top"
          color={play === 'white' ? 'black' : 'white'}
          turn={turn}
          winner={winner}
          game={game}
          mobile={mobile}
          timePressureSec={TIME_PRESSURE}
          scoresheetTimeMs={customPlayersTimeMs}
        />
      }
      player2={
        <PlayerPanelWidget
          orientation="bottom"
          color={play === 'white' ? 'white' : 'black'}
          turn={turn}
          winner={winner}
          game={game}
          mobile={mobile}
          timePressureSec={TIME_PRESSURE}
          scoresheetTimeMs={customPlayersTimeMs}
        />
      }
      scoresheet={<Scoresheet {...scoresheet.props} enableGlobalShortcuts />}
      actions={
        inProgress && isPlayer ? (
          <>
            <ButtonDraw
              disabled={Boolean(
                game.activeDrawRequest ||
                  player?.drawRequestsLeft === 0 ||
                  !fullTurnHappened,
              )}
              onClick={handleOfferDraw}
            />
            <ButtonResign disabled={!fullTurnHappened} onClick={handleResign} />
          </>
        ) : (
          <ButtonLeave
            system={game.tournament?.system}
            disabled={!game}
            onClick={onLeave}
          />
        )
      }
    />
  );
}

function mapWinner(
  apiResult?: components['schemas']['GameResult'],
  clientWinner?: ChessBoardProps['winner'],
) {
  if (apiResult === 'BlackWin') return 'black';
  if (apiResult === 'WhiteWin') return 'white';
  if (apiResult === 'Draw') return 'draw';

  return clientWinner;
}

function useToast() {
  const [toast, setToast] = useState<ReactNode>(null);

  return {
    toast,
    setToast,
    clearToast() {
      setToast(null);
    },
  };
}

function formatTitle(
  game: components['schemas']['GameDto'] | undefined,
  t: ReturnType<typeof useTranslate>['t'],
) {
  if (!game?.tournament) return t('features/game/pages/GamePage/QuickGame');

  const round = game.round?.roundNumber
    ? `. ${t('features/game/pages/GamePage/Round No')}\u00A0${game.round?.roundNumber}`
    : null;
  return `${game.tournament.name}${round ?? ''}`;
}

function formatSpecs(
  game: components['schemas']['GameDto'] | undefined,
  orientation: ChessBoardProps['orientation'] = 'white',
  t: ReturnType<typeof useTranslate>['t'],
) {
  const specs: string[] = [];
  const whiteClock = game?.whiteClockControlSettings;
  const blackClock = game?.blackClockControlSettings;
  const areClocksIdentical =
    whiteClock &&
    blackClock &&
    whiteClock.initialSeconds === blackClock.initialSeconds &&
    whiteClock.addSecondsPerMove === blackClock.addSecondsPerMove;

  const playerTopClock = orientation === 'white' ? blackClock : whiteClock;
  const playerBottomClock = orientation === 'white' ? whiteClock : blackClock;

  if (playerTopClock) {
    specs.push(
      `${Math.floor(playerTopClock.initialSeconds / 60)}+${playerTopClock.addSecondsPerMove}`,
    );
  }

  if (playerBottomClock && !areClocksIdentical) {
    specs.push(
      `${Math.floor(playerBottomClock.initialSeconds / 60)}+${playerBottomClock.addSecondsPerMove}`,
    );
  }

  if (
    game?.tournament?.timeControl &&
    TIME_CONTROL[game.tournament.timeControl]
  ) {
    specs.push(t(TIME_CONTROL[game.tournament.timeControl]!));
  }

  return specs;
}

// FIXME: взять из енума API, когда таковой будет
const TIME_CONTROL: Record<string, keyof LangResources> = {
  Blitz: 'features/game/pages/GamePage/Time Control Blitz',
  Rapid: 'features/game/pages/GamePage/Time Control Rapid',
  Classic: 'features/game/pages/GamePage/Time Control Classic',
};
