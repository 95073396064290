export function IconBack({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.11657 9.68328C1.77492 8.51246 0.604102 7.92705 0.604102 7C0.604102 6.07295 1.77492 5.48754 4.11656 4.31672L8.40836 2.17082C10.3542 1.19788 11.3272 0.711403 12.0386 1.15107C12.75 1.59075 12.75 2.67853 12.75 4.8541V9.1459C12.75 11.3215 12.75 12.4093 12.0386 12.8489C11.3272 13.2886 10.3542 12.8021 8.40836 11.8292L4.11657 9.68328Z"
        fill="currentColor"
      />
    </svg>
  );
}
