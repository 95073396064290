import type { ClockControlType } from '@features/shared/api/typings';

export const gameTimeControlConfig: {
  [k in ClockControlType]: {
    minSeconds: number;
    maxSeconds: number;
    defaultInitialSeconds: number;
    defaultSecondsAdded: number;
  };
} = {
  Blitz: {
    minSeconds: 0,
    maxSeconds: 599,
    defaultInitialSeconds: 5 * 60,
    defaultSecondsAdded: 3,
  },
  Rapid: {
    minSeconds: 600,
    maxSeconds: 3599,
    defaultInitialSeconds: 10 * 60,
    defaultSecondsAdded: 5,
  },
  Classic: {
    minSeconds: 3600,
    maxSeconds: Infinity,
    defaultInitialSeconds: 90 * 60,
    defaultSecondsAdded: 30,
  },
};

export const TOURNAMENT_MINIMUM_TIME_BEFORE_START = 1000 * 60 + 5 * 1000; // 1 минута и 5 секунд.
