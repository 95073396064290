import { ChessBoard } from 'exports/ChessBoard';
import { Arrow } from 'exports/ChessBoard/ChessBoard';
import { MoveInfo, useChessGame } from 'exports/ChessBoard/hooks/useChessGame';
import { PgnEditor } from 'exports/PgnEditor';
import { usePgnEditor } from 'exports/PgnEditor/hooks/usePgnEditor';
import { mapPgnResultToText } from 'exports/PgnEditor/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FEN_STARTING_POSITION } from '@libs/chess/constants';
import { movesToFen } from '@libs/chess/movesToFen';
import { IPgnMove, Pgn } from '@libs/chess/pgn';
import styles from './PgnPage.module.css';
import example from './example.pgn?raw';

/*
    ____,-------------------------------,____
    \   |            Страница           |   /
    /___|-------------------------------|___\
*/

// FIXME: поправить анимации доски

export function PgnPage() {
  const [source, setSource] = useState({ src: example });
  const [text, setText] = useState(source);
  const [showArrows, setShowArrows] = useState(true);
  const [inlineVariants, setInlineVariants] = useState(true);
  const [commentIdent, setCommentIdent] = useState(true);

  const pgn = useMemo(() => Pgn.parse(source.src), [source]);
  const result = mapPgnResultToText(pgn?.result);
  const { props, moves, playSequence, addMove } = usePgnEditor(pgn?.moves, {
    noInlineVariants: !inlineVariants,
    enableGlobalShortcuts: true,
  });

  const initialFen = pgn?.fen ?? FEN_STARTING_POSITION;

  const game = useChessGame({
    side: 'all',
    initialFen,
    onMove: useCallback(
      (m: MoveInfo, san: string) => {
        addMove(san, props.selected);
      },
      [addMove, props.selected],
    ),
  });

  const { fen, from, to } = useMemo(() => {
    return movesToFen(initialFen, playSequence);
  }, [initialFen, playSequence]);

  const reset = game.reset;
  useEffect(() => {
    reset(fen);
  }, [fen, reset]);

  const isPending = source.src !== text.src || pgn?.moves !== moves;

  const arrows = useAltArrows({
    initialFen,
    next: props.next,
    playSequence,
    arrows: game.props.arrows,
    disabled: !showArrows,
  });

  const newSource = useMemo(
    () => new Pgn({ tags: pgn?.rawTags || {}, moves }).serialize(),
    [moves, pgn?.rawTags],
  );

  return (
    <section className={styles.page}>
      <div className={styles.source}>
        <div className={styles.source__board}>
          <ChessBoard
            orientation="white"
            {...game.props}
            selectedFrom={from}
            selectedTo={to}
            arrows={arrows}
          />
        </div>

        <div className={styles.source__controls}>
          <label>
            <input
              type="checkbox"
              checked={showArrows}
              onChange={(e) => {
                setShowArrows(e.target.checked);
              }}
            />{' '}
            Show variant arrows
          </label>
          <label>
            <input
              type="checkbox"
              checked={inlineVariants}
              onChange={(e) => {
                setInlineVariants(e.target.checked);
              }}
            />{' '}
            Inline variants
          </label>
          <label>
            <input
              type="checkbox"
              checked={commentIdent}
              onChange={(e) => {
                setCommentIdent(e.target.checked);
              }}
            />{' '}
            Ident top-level comments
          </label>
          <button disabled={!isPending} onClick={() => setSource({ ...text })}>
            Отрисовать PGN
          </button>
        </div>

        <textarea
          className={styles.source__text}
          defaultValue={source.src}
          onChange={(e) => {
            setText({ src: e.target.value });
          }}
        />

        <h2>Results</h2>

        <div className={styles.source__text}>{newSource}</div>
      </div>

      <div className={styles.viewer}>
        <PgnEditor
          className={styles.viewer__editor}
          {...props}
          noCommentIdent={!commentIdent}
          result={result}
        />
      </div>
    </section>
  );
}

/*
    ____,-------------------------------,____
    \   |             Хуки            |   /
    /___|-------------------------------|___\
*/

function useAltArrows({
  initialFen,
  next,
  playSequence,
  arrows,
  disabled = false,
}: {
  initialFen?: string;
  next?: IPgnMove;
  playSequence?: IPgnMove[];
  arrows?: Arrow[];
  disabled?: boolean;
}) {
  return useMemo(() => {
    if (!next?.alts || disabled) return arrows;
    const c = movesToFen(initialFen, playSequence);
    const n = movesToFen(c.fen, [next]);
    const alts = next.alts.map((a) => movesToFen(c.fen, [a[0]!]));
    return [
      {
        type: 'stockfish',
        thickness: 'primary',
        from: n.from!,
        to: n.to!,
      },
      ...alts.map((a) => ({
        type: 'stockfish',
        thickness: 'secondary',
        from: a.from!,
        to: a.to!,
      })),
      ...(arrows ?? []),
    ] as Arrow[];
  }, [next, disabled, arrows, initialFen, playSequence]);
}
