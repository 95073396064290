import { Chess, Move } from 'chess.js';
import {
  useCallback,
  useMemo,
  useRef,
  useState,
  KeyboardEvent,
  PointerEvent,
} from 'react';
import {
  PieceCode,
  Color,
  SquareName,
  PromotionPiece,
} from '@libs/chess/types';
import { Props, UserArrow } from '../ChessBoard';
import {
  Action,
  AllowedAction,
  SelectionFSM,
  SelectionIdle,
  SelectionSelected,
} from './useChessGame.fsm';

/*
    ____,-------------------------------,____
    \   |              Hook             |   /
    /___|-------------------------------|___\
*/

export type Args = {
  side: 'white' | 'black' | 'all' | 'none';
  initialFen?: string;
  initialSelection?: { from: SquareName; to: SquareName; action: Action };
  allowPremove?: boolean;
  autoPromotion?: PromotionPiece;
  onMove?: (m: MoveInfo, san: string) => void;
  onPremove?: (m: MoveInfo | null) => void;
};

export type Value = {
  reset: (fen?: string) => void;
  move: (move: ExternalMove) => void;

  props: MappedProps;
};

export type MoveInfo = {
  from: SquareName;
  to: SquareName;
  promotion?: PromotionPiece;
};

type ExternalMove = {
  fen: string;
  prevFen: string;
  from: SquareName;
  to: SquareName;
  promotion?: PromotionPiece;
};

type MappedProps = Pick<
  Props,
  | 'position'
  | 'interactive'
  //
  | 'isPremove'
  | 'selectedFrom'
  | 'selectedTo'
  | 'premoveFrom'
  | 'premoveTo'
  | 'moves'
  | 'promotingFrom'
  | 'promotingTo'
  | 'arrows'
  //
  | 'winner'
  //
  | 'onPieceDrag'
  | 'onSquareClick'
  | 'onSquarePointerDown'
  | 'onSquareDrop'
  | 'onPromotion'
  | 'onKeyDown'
  | 'onArrowDrag'
  | 'onArrowPlace'
>;

export function useChessGame({
  side,
  initialFen,
  initialSelection,
  allowPremove = false,
  autoPromotion,
  onMove,
  onPremove,
}: Args): Value {
  if (side === 'all' && allowPremove) {
    throw new Error(
      'useChessGame: allowPremove is not supported together with side=all',
    );
  }

  // ------------------------------------------
  // Store and state

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const chess = useMemo(() => new Chess(initialFen), []);

  const gameOver = chess.isGameOver();
  const turnColor: Color = chess.turn();
  const allow = mapAllowedAction(side, turnColor, allowPremove, gameOver);

  // ---

  const [state, update] = useState<SelectionFSM>(() =>
    initialSelection
      ? new SelectionSelected(
          initialSelection.action,
          initialSelection.from,
          initialSelection.to,
        )
      : new SelectionIdle(),
  );

  //
  // Обернём все необходимые данные в ref для доступа в коллбэках
  //

  const store = {
    side,
    allowPremove,
    autoPromotion,
    state,
    chess,
    allow,
    onMove,
    onPremove,
  };
  const storeRef = useRef(store);
  storeRef.current = store;

  // ---

  //
  // Чтобы не городить с независимыми состояниями для selection и premove, используем
  // prevMoveRef для хранения последнего хода
  //

  const isPremove = state.action === 'premove';
  const prevMoveRef = useRef(state);

  if (state.name === 'selected' && state.action === 'move') {
    prevMoveRef.current = state;
  }

  // Использовать prevMoveRef при премуве или пока нет своего хода
  // (чтобы был виден ход последний противника)
  const showPrev = allow === 'premove' || state.name === 'idle';

  // ------------------------------------------
  // Moves and Premoves

  const handleMove = useCallback((move: Move) => {
    const { chess, onMove, onPremove, state, autoPromotion } = storeRef.current;
    const m =
      autoPromotion && move.promotion
        ? {
            from: move.from,
            to: move.to,
            promotion: move.promotion as PromotionPiece,
          }
        : {
            from: move.from,
            to: move.to,
            piece: mapMoveToPieceCode(move),
          };

    if (state.action === 'premove') {
      onPremove?.(m);
    } else {
      const san = chess.move(move)?.san;
      onMove?.(m, san);
    }
  }, []);

  // ------------------------------------------
  // Selection

  //
  // Выбор клетки происходит по ponterdown, а вот снятие двухфазное:
  //  - PointerDown: устанавливает флаг (в SelectionTargeting.transition)
  //  - Click: проверяет флаг и отменяет выбор клетки (в SelectionTargeting.transitionCleanup)
  //
  // Без этого при перетаскивании ранее выбранной фигуры выбор сначала снимется,
  // и вернётся при перемещении мышки
  //

  const handlePointerDown = useCallback(
    (s: SquareName, _: unknown, e: PointerEvent) => {
      // сбросить стрелочки при ЛКМ
      if (e.button === 0 && !e.ctrlKey) setArrows(undefined);

      // игнорировать ЛКМ при зажатом ctrl (это режим рисования стрелочек)
      if (e.ctrlKey) return;

      const { chess, state, allow, onPremove } = storeRef.current;

      if (allow === 'none' || e.button !== 0) return;

      let next: SelectionFSM =
        state.name === 'promoting' || state.name === 'selected'
          ? state.toIdle()
          : state;

      // Сбросим premove
      if (state.name === 'selected' && state.action === 'premove') {
        onPremove?.(null);
      }

      // CORNER CASE: клик на туже пешку, которую двигали, но уже в новой клетке
      if (state.name === 'promoting' && state.to === s) {
        update(next);
        return;
      }

      switch (next.name) {
        case 'idle':
          next = next.transition({
            from: s,
            allowedAction: allow,
            chess,
          });
          break;
        case 'targeting':
          next = next.transition({
            to: s,
            chess,
            canReset: true,
            canReselect: true,
            moveCallback: handleMove,
            autoPromotion,
          });
          break;
      }

      update(next);
    },
    [autoPromotion, handleMove],
  );

  const handleClick = useCallback(() => {
    const { state } = storeRef.current;

    if (state.name === 'targeting') {
      update(state.transitionCleanup());
    }
  }, []);

  // ------------------------------------------
  // Drag

  const handleDrag = useCallback((s: SquareName) => {
    const { allow, state, chess } = storeRef.current;

    if (state.name === 'idle') {
      update(
        state.transition({
          allowedAction: allow,
          from: s,
          chess,
        }),
      );
      return true;
    }

    return state.name === 'targeting' && state.from === s;
  }, []);

  const handleDrop = useCallback(
    (_from: SquareName, to: SquareName) => {
      const { state, chess } = storeRef.current;

      if (state.name !== 'targeting') return;

      update(
        state.transition({
          to,
          chess,
          canReset: false,
          canReselect: false,
          moveCallback: handleMove,
          autoPromotion,
        }),
      );
    },
    [autoPromotion, handleMove],
  );

  // ------------------------------------------
  // Promotion

  const handlePromotion = useCallback((promoteTo: PromotionPiece) => {
    const { state, chess, onMove, onPremove } = storeRef.current;

    if (state.name !== 'promoting') return;

    const move: MoveInfo = {
      from: state.promotion.from,
      to: state.promotion.to,
      promotion: promoteTo,
    };

    // --- PREMOVE

    if (state.action === 'premove') {
      update(state.toSelected());
      onPremove?.(move);
      return;
    }

    // --- MOVE

    const san = chess.move({ ...state.promotion, promotion: promoteTo }).san;
    update(state.toSelected());
    onMove?.(move, san);
  }, []);

  // ------------------------------------------
  // Keyboard

  const handleKeyboard = useCallback((e: KeyboardEvent) => {
    const { state, onPremove } = storeRef.current;

    if (
      e.code === 'Escape' &&
      state.name !== 'idle' &&
      (state.name !== 'selected' || state.action === 'premove')
    ) {
      update(state.toIdle());

      if (state.action === 'premove') {
        onPremove?.(null);
      }
    }
  }, []);

  // ------------------------------------------
  // Arrows

  const [arrows, setArrows] = useState<UserArrow[] | undefined>();
  const handleArrow = useCallback((arrow: UserArrow) => {
    setArrows((arr = []) => {
      const duplicate = arr.find(
        (a) => a.to === arrow.to && a.from === arrow.from,
      );

      if (duplicate) {
        const next = arr.filter((a) => a !== duplicate);
        if (duplicate.color !== arrow.color) {
          next.push(arrow);
        }
        return next.length ? next : undefined;
      }
      return [...arr, arrow];
    });
  }, []);

  const handleArrowDrag = useCallback(() => {
    const { state } = storeRef.current;

    if (state.name !== 'idle' && state.name !== 'selected') {
      update(state.toIdle());
    }

    return true;
  }, []);

  // ------------------------------------------
  // External commands

  const handleExternalReset = useCallback((fen?: string) => {
    const { chess } = storeRef.current;

    // сбросить стрелочки
    setArrows(undefined);

    if (fen) {
      chess.load(fen);
    } else {
      chess.reset();
    }

    update(new SelectionIdle());
  }, []);

  const handleExternalMove = useCallback(
    ({ from, to, promotion, fen, prevFen }: ExternalMove) => {
      const { chess, state, allowPremove, side } = storeRef.current;

      // сбросить стрелочки
      setArrows(undefined);

      let nextState: SelectionFSM = new SelectionSelected('move', from, to);

      // FIXME: удалть split(' ')[0] когда API поправит каунтеры
      const internalFen = chess.fen().split(' ')[0];
      // FIXME: удалть split(' ')[0] когда API поправит каунтеры
      if (prevFen.split(' ')[0] === internalFen) {
        chess.move({ from, to, promotion });

        //
        // Обработать ситуацию, что во время premove противник сделал ход
        //

        if (state.name === 'targeting' && state.action === 'premove') {
          nextState = new SelectionIdle().transition({
            allowedAction: mapAllowedAction(
              side,
              chess.turn(),
              allowPremove,
              chess.isGameOver(),
            ),
            from: state.from,
            chess: chess,
          });
        }
        // FIXME: удалть split(' ')[0] когда API поправит каунтеры
      } else if (fen.split(' ')[0] !== internalFen) {
        chess.load(fen);
      }

      update(nextState);
    },
    [],
  );

  // ------------------------------------------
  // Return

  return {
    move: handleExternalMove,
    reset: handleExternalReset,
    props: {
      position: chess.fen(),
      interactive: mapInteractive(allow, turnColor),
      isPremove,
      selectedFrom: !showPrev ? state.from : prevMoveRef.current.from,
      selectedTo: !showPrev ? state.to : prevMoveRef.current.to,
      premoveFrom: isPremove ? state.from : undefined,
      premoveTo: isPremove ? state.to : undefined,
      moves: useMemo(() => state.moves?.map((m) => m.to), [state.moves]),
      promotingFrom: state.promotion?.from,
      promotingTo: state.promotion?.to,
      winner: gameOver ? mapWinner(chess) : undefined,
      arrows,
      onPieceDrag: handleDrag,
      onSquareClick: handleClick,
      onSquarePointerDown: handlePointerDown,
      onSquareDrop: handleDrop,
      onPromotion: handlePromotion,
      onKeyDown: handleKeyboard,
      onArrowDrag: handleArrowDrag,
      onArrowPlace: handleArrow,
    },
  };
}

/*
    ____,-------------------------------,____
    \   |            Helpers            |   /
    /___|-------------------------------|___\
*/

function mapAllowedAction(
  side: Args['side'],
  turnColor: Color,
  allowPremove: boolean,
  gameOver: boolean,
): AllowedAction {
  if (gameOver || side === 'none') return 'none';
  if (side === 'all') return 'move';

  const turnSide = turnColor === 'w' ? 'white' : 'black';

  if (side === turnSide) return 'move';
  return allowPremove ? 'premove' : 'none';
}

function mapInteractive(
  allow: AllowedAction,
  turnColor: Color,
): Required<Props>['interactive'] {
  switch (allow) {
    case 'none':
      return 'none';
    case 'move':
      return turnColor === 'w' ? 'white' : 'black';
    case 'premove':
      return turnColor === 'w' ? 'black' : 'white';
  }
}

function mapMoveToPieceCode(move: Move): PieceCode {
  return `${move.color}${move.piece.toLocaleUpperCase()}` as PieceCode;
}

function mapWinner(chess: Chess): 'draw' | 'white' | 'black' {
  if (chess.isDraw()) return 'draw';
  return chess.turn() === 'b' ? 'white' : 'black';
}
