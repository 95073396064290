import { ReactNode } from 'react';
import { cx } from '@libs/classnames';
import styles from './StreamingPage.module.css';

export type Props = {
  className?: string;
  header?: ReactNode;
  content?: ReactNode;
  gameList?: ReactNode;
};

export function StreamingPage({ className, header, content, gameList }: Props) {
  return (
    <div className={cx(styles.streaming_page, className)}>
      <section className={styles.header}>{header}</section>
      <section className={styles.content}>{content}</section>
      <section className={styles.game_list}>{gameList}</section>
    </div>
  );
}
