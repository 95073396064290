import { useUserSocket } from '@features/shared/api';
import { useTournamentGamesSocket } from '@features/shared/api/streams';
import { ResultsTableParticipant } from '@features/shared/api/typings';
import {
  useTournamentCardQuery,
  useTournamentResultsQuery,
} from '@features/tournament/api/hooks';
import { useGoToGame } from '@features/tournament/hooks/useGoToGame';
import { EliminationBrackets } from '@features/tournament/ui/components/EliminationBrackets';
import { getPresentRounds } from '@features/tournament/utils/tournamentHelpers';
import { ResultsTable } from './ResultsTable';
import styles from './ResultsTable.module.css';

type Props = { clubId: string; tournamentId: string };

export function ResultsTableWidget({ clubId, tournamentId }: Props) {
  const tournamentQUery = useTournamentCardQuery(clubId, tournamentId, {
    staleTime: Infinity,
  });
  const tournament = tournamentQUery.data;

  const resultsQuery = useTournamentResultsQuery(
    clubId,
    tournamentId,
    tournament &&
      ['InProgress', 'Paused', 'Finished'].includes(tournament.status),
  );

  let results;
  const system = tournament?.system;
  const data = resultsQuery.data;
  if (system === 'RoundRobin') results = data?.roundRobin?.results;
  if (system === 'OneOnOne') results = data?.oneOnOne?.results;

  // Отслеживание изменений игр
  const presentRounds = getPresentRounds(tournament?.rounds);
  const lastRound = presentRounds?.[presentRounds.length - 1];
  useTournamentGamesSocket({
    tournamentId,
    roundNumber: lastRound?.roundNumber ?? NaN,
    enabled: lastRound?.status === 'InProgress',
    cb: (e) => {
      if (e.eventType === 'GameChanged') {
        const result = e.currentState.result;
        if (
          result === 'Draw' ||
          result === 'BlackWin' ||
          result === 'WhiteWin'
        ) {
          resultsQuery.refetch();
        }
      } else if (e.eventType === 'NewGameStarted') {
        resultsQuery.refetch();
      }
    },
  });

  // Отслеживание изменений турнира
  // Места становятся известны только после завершения матча, а не после
  // завершения последнего раунда
  // Также догружаем доп. раунды при драфте и финише.
  useUserSocket((e) => {
    switch (e.eventType) {
      case 'TournamentFinished':
      case 'RoundFinished':
      case 'RoundStarted':
      case 'RoundDrafted':
        if (e.tournamentId === tournamentId) {
          resultsQuery.refetch();
        }
        break;
    }
  });

  const goToGame = useGoToGame(clubId, tournamentId);

  if (!data || !tournament) return null;

  if (system === 'Knockout') {
    results = data.knockOutResults;
    if (!results) return null;
    return (
      <EliminationBrackets
        className={styles.eliminationBrackets}
        rounds={results.rounds}
        onGameClick={goToGame}
      />
    );
  }

  return (
    <ResultsTable
      // TODO fix typings
      rows={results as ResultsTableParticipant[]}
      rounds={tournament.rounds}
      system={tournament.system}
      eventFinished={tournament.status === 'Finished'}
      additionalScoresOrder={tournament.additionalScoreOrder}
      onGameClick={goToGame}
    />
  );
}
