import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  KeyboardEvent as ReactKeyboardEvent,
} from 'react';
import { cx } from '@libs/classnames';
import { IconBack, IconFastBack } from '@ui/icons/PgnContols';
import styles from './Scoresheet.module.css';

export type Props<T extends Move = Move> = {
  className?: string;
  moves: T[];
  selected?: T;
  onNavigate: (nextSelected: T, prevSelected: T) => void;
  result?: string;
  enableGlobalShortcuts?: boolean;
};

type Move = { san: string };

/*
    ____,-------------------------------,____
    \   |          Компонент            |   /
    /___|-------------------------------|___\
*/

const NO_MOVES = ['—'];
const typedMemo: <T>(c: T) => T = memo;

export const Scoresheet = typedMemo(function Scoresheet<T extends Move>({
  className,
  moves,
  selected,
  onNavigate,
  result,
  enableGlobalShortcuts,
}: Props<T>) {
  const fullmoves = useMemo(
    () => (moves.length ? toFullMoves(moves) : NO_MOVES),
    [moves],
  );

  // --- Cкролл к последнему полному ходу

  const { refScrollable } = useScrollToLatest(moves, result, selected);

  // --- Навигация

  const {
    disableBackward,
    disableForward,
    handleFastBackward,
    handleBackward,
    handleForward,
    handleFastForward,
    handleMove,
  } = useNavigate(selected, moves, onNavigate);

  // --- Навигация с клавиатуры

  const ref = useRef<HTMLElement>(null);
  const handleKeyboard = useCallback(
    (e: KeyboardEvent | ReactKeyboardEvent) => {
      const focused = document.activeElement;

      if (
        enableGlobalShortcuts &&
        focused &&
        ((focused.nodeName === 'INPUT' &&
          !['checkbox', 'radio'].includes(focused.getAttribute('type')!)) ||
          focused.nodeName === 'TEXTAREA' ||
          focused.nodeName === 'SELECT' ||
          focused.getAttribute('contentEditable'))
      )
        return;

      const chores = () => {
        ref.current?.focus();
        e.preventDefault();
      };

      if (e.code === 'ArrowRight') {
        handleForward();
        chores();
      } else if (e.code === 'ArrowLeft') {
        handleBackward();
        chores();
      }
    },
    [enableGlobalShortcuts, handleBackward, handleForward],
  );

  useEffect(() => {
    if (!enableGlobalShortcuts) return;

    window.addEventListener('keydown', handleKeyboard);

    return () => window.removeEventListener('keydown', handleKeyboard);
  }, [enableGlobalShortcuts, handleKeyboard]);

  // --- Подскролл к выделенному

  const { refSelected } = useFocusSelected(selected);

  // --- Отрисовка

  const last = moves[moves.length - 1];

  return (
    <section
      className={cx(styles.scoresheet, className)}
      onKeyDown={enableGlobalShortcuts ? undefined : handleKeyboard}
      tabIndex={0}
      ref={ref}
    >
      <ul className={styles.controls}>
        <li>
          <button disabled={disableBackward} onClick={handleFastBackward}>
            <IconFastBack />
          </button>
        </li>
        <li>
          <button
            className={styles.red}
            disabled={disableBackward}
            onClick={handleBackward}
          >
            <IconBack />
          </button>
        </li>
        <li>
          <button disabled={disableForward} onClick={handleForward}>
            <IconBack className={styles.reverse} />
          </button>
        </li>
        <li>
          <button disabled={disableForward} onClick={handleFastForward}>
            <IconFastBack className={styles.reverse} />
          </button>
        </li>
      </ul>
      <div className={styles.scrollable} ref={refScrollable}>
        <table className={styles.sheet} cellPadding={0} cellSpacing={0}>
          <colgroup>
            <col width="14%" />
            <col width="43%" />
            <col width="43%" />
          </colgroup>
          <tbody>
            {fullmoves.map((m, i) => {
              const white = moves[findMoveIndex(i, 0)];
              const black = moves[findMoveIndex(i, 1)];
              return (
                <tr
                  translate="no"
                  key={i}
                  ref={
                    selected &&
                    (selected === white || selected === black) &&
                    (selected !== last || !result)
                      ? refSelected
                      : undefined
                  }
                >
                  <td>{i + 1}</td>
                  <td className={styles.white}>
                    {white && (
                      <button
                        className={
                          selected === white ? styles.selected : undefined
                        }
                        onClick={() => handleMove(white)}
                      >
                        {m[0]}
                      </button>
                    )}
                    {!white && <button disabled>{m[0]}</button>}
                  </td>
                  <td>
                    {black && (
                      <button
                        className={
                          selected === black ? styles.selected : undefined
                        }
                        onClick={() => handleMove(black)}
                      >
                        {m[1]}
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
            {result && (
              <tr ref={selected && selected === last ? refSelected : undefined}>
                <td></td>
                <td className={styles.result} colSpan={2}>
                  {result}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
});

/*
    ____,-------------------------------,____
    \   |              Хуки             |   /
    /___|-------------------------------|___\
*/

function useScrollToLatest(moves: Move[], result?: string, selected?: Move) {
  const refScrollable = useRef<HTMLDivElement>(null);
  const refFirstTime = useRef(false);

  useLayoutEffect(
    () => {
      if (!refScrollable.current || selected) {
        refFirstTime.current = true;
        return;
      }

      refScrollable.current.scrollTo({
        top: refScrollable.current.scrollHeight,
        behavior: refFirstTime.current ? 'smooth' : 'instant',
      });
      refFirstTime.current = true;
    },
    /* Скроллим при изменении кол-ва ходов или изменении результата */
    [moves.length, result, selected],
  );

  return { refScrollable };
}

function useNavigate<T extends Move>(
  selected: T | undefined,
  moves: T[],
  onNavigate: (nextSelected: T, prevSelected: T) => void,
) {
  const disable = moves.length === 0;
  const disableBackward =
    disable ||
    moves.length === 1 /* с одним ходом тоже некуда переключаться */ ||
    selected === moves[0];
  const disableForward =
    disable || !selected || selected === moves[moves.length - 1];

  const handleBackward = () => {
    if (disableBackward) return;
    const prevInx = findSelectedIndex(moves, selected);
    onNavigate(moves[prevInx - 1]!, moves[prevInx]!);
  };
  const handleFastBackward = () => {
    if (disableBackward) return;
    const prevIx = findSelectedIndex(moves, selected);
    onNavigate(moves[0]!, moves[prevIx]!);
  };
  //-
  const handleForward = () => {
    if (disableForward) return;
    const prevIx = findSelectedIndex(moves, selected);
    onNavigate(moves[prevIx + 1]!, moves[prevIx]!);
  };
  const handleFastForward = () => {
    if (disableForward) return;
    const next = moves[moves.length - 1];
    const prevIx = findSelectedIndex(moves, selected);
    onNavigate(next!, moves[prevIx]!);
  };
  //-
  const handleMove = (move: T) => {
    if (disable) return;
    const prevIx = findSelectedIndex(moves, selected);
    const prev = moves[prevIx]!;
    if (selected && prev === move) return;
    onNavigate(move, prev);
  };

  return {
    disableBackward,
    disableForward,
    handleFastBackward,
    handleBackward,
    handleForward,
    handleFastForward,
    handleMove,
  };
}

function useFocusSelected(selected: Move | undefined) {
  const refMounted = useRef(false);

  const refSelected = useRef<HTMLTableRowElement>(null);
  useEffect(() => {
    if (!refSelected.current) return;

    refSelected.current.scrollIntoView({
      block: refMounted.current ? 'nearest' : 'center',
      behavior: refMounted.current ? 'smooth' : 'instant',
    });
  }, [selected]);

  useEffect(() => {
    refMounted.current = true;
  }, []);

  return {
    refSelected,
  };
}

/*
    ____,-------------------------------,____
    \   |            Утилиты            |   /
    /___|-------------------------------|___\
*/

function toFullMoves(moves: Move[]): string[][] {
  return moves.reduce((sink, m, i) => {
    const isBlack = i % 2 === 1;

    if (isBlack) {
      sink[sink.length - 1]?.push(m.san);
    } else {
      sink.push([m.san]);
    }

    return sink;
  }, [] as string[][]);
}

function findSelectedIndex(moves: Move[], selected?: Move): number {
  if (!moves.length) return -1;
  if (!selected) return moves.length - 1;
  return moves.findIndex((m) => m === selected);
}

function findMoveIndex(fullmoveIx: number, pairIx: number): number {
  return fullmoveIx * 2 + pairIx;
}
