// In: 0 — Infinity
// Out: A — Z, A1 — Z1, A2 — Z2...
export function indexToAlpha(index: number, from = 'A', to = 'Z') {
  const codeFrom = from.charCodeAt(0);
  const codeTo = to.charCodeAt(0);
  const MAX = 1 + codeTo - codeFrom;
  const alpha = index % MAX;
  const num = (index - alpha) / MAX;

  return String.fromCharCode(codeFrom + alpha) + (num || '');
}
