import {
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
  Ref,
  useCallback,
  useState,
} from 'react';
import { cx } from '@libs/classnames';
import styles from './Input.module.css';

export type Props = {
  className?: string;
  name?: string;
  inputRef?: Ref<HTMLInputElement | HTMLTextAreaElement>;
  type?: 'text' | 'textarea' | 'number' | 'date' | 'time' | 'password';
  disabled?: boolean;
  value?: string;
  onChange: (newValue: string) => void;
  error?: boolean;
  autoFocus?: boolean;
  leftLabel?: ReactNode;
  rightLabel?: ReactNode;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  placeholder?: string;
  invisible?: boolean;
  min?: string;
  max?: string;
  onEnter?: (value: string) => void;
  onEscape?: (value: string) => void;
  onUp?: (e: KeyboardEvent) => void;
  onDown?: (e: KeyboardEvent) => void;
};
export function Input({
  className,
  name,
  value,
  onChange,
  disabled,
  error,
  autoFocus,
  leftLabel,
  rightLabel,
  type,
  inputRef,
  onFocus,
  onBlur,
  onClick,
  placeholder,
  invisible,
  min,
  max,
  onEnter,
  onEscape,
  onUp,
  onDown,
}: Props) {
  const [isFocused, setIsFocused] = useState(false);
  const handleOnFocus = useCallback(() => {
    onFocus?.();
    setIsFocused(true);
  }, [onFocus, setIsFocused]);
  const handleOnBlur = useCallback(() => {
    onBlur?.();

    if (type === 'number') {
      if (min !== undefined && (!value || Number(value) < Number(min)))
        return onChange(min);
      else if (max !== undefined && value && Number(value) > Number(max))
        onChange(max);
      else if (value?.startsWith('0') && value && Number(value) > 0)
        onChange(Number(value).toString());
    }

    setIsFocused(false);
  }, [onBlur, setIsFocused, onChange, min, value, type, max]);

  const handleChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let newVal = evt.target.value;
      if (type === 'number') {
        newVal = newVal.replaceAll(/\D/g, '');
      }

      onChange(newVal);
    },
    [onChange, type],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.code === 'Enter' && onEnter) onEnter(value || '');
      if (e.code === 'Escape' && onEscape) onEscape(value || '');
      if (e.code === 'ArrowDown' && onDown) onDown(e);
      if (e.code === 'ArrowUp' && onUp) onUp(e);
    },
    [onEnter, onEscape, onDown, onUp, value],
  );

  if (type === 'textarea') {
    return (
      <div
        onClick={onClick}
        className={cx(className, styles.root, {
          [styles.focus]: isFocused,
          [styles.disabled]: disabled,
          [styles.error]: error,
          [styles.invisible]: invisible,
        })}
      >
        <textarea
          name={name}
          disabled={disabled}
          autoFocus={autoFocus}
          ref={inputRef as Ref<HTMLTextAreaElement>}
          className={styles.textArea}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
        />
      </div>
    );
  }

  return (
    <div
      onClick={onClick}
      className={cx(className, styles.root, {
        [styles.focus]: isFocused,
        [styles.disabled]: disabled,
        [styles.error]: error,
        [styles.invisible]: invisible,
      })}
    >
      {leftLabel && <span>{leftLabel}</span>}
      <input
        name={name}
        disabled={disabled}
        autoFocus={autoFocus}
        ref={inputRef as Ref<HTMLInputElement>}
        className={styles.input}
        type={type === 'number' ? 'text' : type}
        inputMode={type === 'number' ? 'numeric' : 'text'}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        min={min}
        max={max}
        onKeyDown={handleKeyDown}
      />
      {rightLabel && <span>{rightLabel}</span>}
    </div>
  );
}
