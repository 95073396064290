import { ReactNode, useCallback } from 'react';
import { cx } from '@libs/classnames';
import { PageSubTitle } from '@ui/components/PageSubTitle';
import styles from './PageTabs.module.css';

type Tab<T> = { value: T; label: ReactNode; disabled?: boolean };

export type Props<T> = {
  className?: string;
  tabClassName?: string;
  tabs: Tab<T>[];
  value: T;
  onChange: (newValue: T) => void;
  inverted?: boolean;
  disabled?: boolean;
};
export function PageTabs<T extends string>({
  className,
  tabClassName,
  tabs,
  value,
  onChange,
  inverted,
  disabled,
}: Props<T>) {
  return (
    <div
      className={cx(className, styles.root, { [styles.inverted]: inverted })}
    >
      {tabs.map((t: Tab<T>) => (
        <Tab
          key={t.value}
          className={tabClassName}
          active={value === t.value}
          label={t.label}
          value={t.value}
          onChange={onChange}
          disabled={disabled || t.disabled}
        />
      ))}
    </div>
  );
}

type TabProps<T> = {
  className?: string;
  label: ReactNode;
  value: T;
  active: boolean;
  onChange: (newValue: T) => void;
  disabled?: boolean;
};
function Tab<T>({
  className,
  label,
  value,
  active,
  onChange,
  disabled,
}: TabProps<T>) {
  const handleClick = useCallback(() => onChange(value), [value, onChange]);
  return (
    <PageSubTitle
      className={cx(
        styles.tab,
        {
          [styles.active]: active,
          [styles.disabled]: disabled,
        },
        className,
      )}
      onClick={handleClick}
    >
      {label}
    </PageSubTitle>
  );
}
