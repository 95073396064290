export function IconFastBack({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="24"
      height="14"
      viewBox="0 0 24 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.36657 9.68328C3.02492 8.51246 1.8541 7.92705 1.8541 7C1.8541 6.07295 3.02492 5.48754 5.36656 4.31672L9.65836 2.17082C11.6042 1.19788 12.5772 0.711403 13.2886 1.15107C14 1.59075 14 2.67853 14 4.8541V9.1459C14 11.3215 14 12.4093 13.2886 12.8489C12.5772 13.2886 11.6042 12.8021 9.65836 11.8292L5.36657 9.68328Z"
        fill="currentColor"
      />
      <path
        d="M15.3666 9.68328C13.0249 8.51246 11.8541 7.92705 11.8541 7C11.8541 6.07295 13.0249 5.48754 15.3666 4.31672L19.6584 2.17082C21.6042 1.19788 22.5772 0.711403 23.2886 1.15107C24 1.59075 24 2.67853 24 4.8541V9.1459C24 11.3215 24 12.4093 23.2886 12.8489C22.5772 13.2886 21.6042 12.8021 19.6584 11.8292L15.3666 9.68328Z"
        fill="currentColor"
      />
    </svg>
  );
}
