import { useCallback } from 'react';
import { ClockControlType } from '@features/shared/api/typings';
import { ClockControlInput } from '@features/tournament/ui/components/ClockControlInput/ClockControlInput';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Tabs } from '@ui/components/Tabs';

const ARMAGEDDON_TOGGLE_TABS = [
  { value: true, label: 'Да' },
  { value: false, label: 'Нет' },
];

// TODO reuse
type ClockControlSetting = {
  initialSeconds: number;
  addSecondsPerMove: number;
};

type ArmageddonValue = {
  armageddonEnabled: boolean;
  armageddonSettings: {
    clockControl: ClockControlType;
    clockControlSettingsPlayer1: ClockControlSetting;
    clockControlSettingsPlayer2: ClockControlSetting;
  };
};

type Props = {
  alwaysEnabled?: boolean;
  value: ArmageddonValue;
  onChange: (armageddonValue: ArmageddonValue) => void;
  disabled?: boolean;
};

export function ArmageddonInput({
  alwaysEnabled,
  value,
  onChange,
  disabled,
}: Props) {
  const {
    armageddonEnabled,
    armageddonSettings: {
      clockControl,
      clockControlSettingsPlayer1,
      clockControlSettingsPlayer2,
    },
  } = value;

  const onToggleChange = useCallback(
    (newValue: boolean) => {
      if (alwaysEnabled) return onChange({ ...value, armageddonEnabled: true });
      onChange({ ...value, armageddonEnabled: newValue });
    },
    [onChange, value, alwaysEnabled],
  );

  const onClockControlChange = useCallback(
    (newValue: ArmageddonValue['armageddonSettings']) => {
      onChange({ ...value, armageddonSettings: newValue });
    },
    [onChange, value],
  );

  return (
    <>
      <FormRow>
        <FormLabel>Армагеддон</FormLabel>
        <FormInput>
          <Tabs
            tabs={ARMAGEDDON_TOGGLE_TABS}
            value={alwaysEnabled ? true : value.armageddonEnabled}
            onChange={onToggleChange}
            disabled={disabled || alwaysEnabled}
          />
        </FormInput>
      </FormRow>

      {(armageddonEnabled || alwaysEnabled) && (
        <ClockControlInput
          perPlayer
          label="Время"
          labelIndented
          player1Label="Белые"
          player2Label="Черные"
          value={{
            clockControl: clockControl,
            clockControlSettingsPlayer1: clockControlSettingsPlayer1,
            clockControlSettingsPlayer2: clockControlSettingsPlayer2,
          }}
          onChange={onClockControlChange}
          disabled={disabled || !armageddonEnabled}
        />
      )}
    </>
  );
}
