import { Controller, useFormContext } from 'react-hook-form';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Tabs } from '@ui/components/Tabs';

const TABS = [
  { value: 1, label: '1' },
  { value: 2, label: '2', disabled: true },
  { value: 3, label: '3', disabled: true },
  { value: 4, label: '4', disabled: true },
];

export function RoundRobinRoundsCount() {
  const { control } = useFormContext<TournamentEditFormValues>();
  return (
    <FormRow>
      <FormLabel>Количество кругов</FormLabel>
      <FormInput>
        <Controller
          name="roundRobinSettings.roundsCount"
          control={control}
          render={({ field: { value, onChange, disabled } }) => (
            <Tabs
              tabs={TABS}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        />
      </FormInput>
    </FormRow>
  );
}
