import { useState } from 'react';
import { KnockoutResultsRound } from '@features/shared/api/typings';
import { BracketsMatch } from '@features/tournament/ui/components/BracketsMatch';
import { KnockoutRoundName } from '@features/tournament/ui/components/KnockoutRoundName';
import { useMediaQuery } from '@ui/components/MediaQueryProvider';
import { PageTabs } from '@ui/components/PageTabs';
import { CapsuleTabs } from 'ui/components/CapsuleTabs';
import styles from './TournamentKnockoutResults.module.css';

export type TournamentKnockoutResultsProps = {
  rounds: KnockoutResultsRound[];
  onGameClick?: (gameId: string) => void;
};

export function TournamentKnockoutResults({
  rounds,
  onGameClick,
}: TournamentKnockoutResultsProps) {
  const isMobile = useMediaQuery() === 'mobile';
  const visibleRounds = rounds
    .filter(
      (r) =>
        r.roundType === 'WinnerBranch' ||
        r.roundType === 'Final' ||
        r.roundType === 'ThirdPlace',
    )
    .map((r) => ({
      label: (
        <KnockoutRoundName
          preset={isMobile ? 'full' : 'short'}
          roundType={r.roundType}
          roundName={r.roundName}
        />
      ),
      value: r.roundId,
    }));

  const [currentRoundId, setCurrentRoundId] = useState(
    visibleRounds[visibleRounds.length - 1]?.value,
  );

  const currentRound =
    rounds.find((r) => r.roundId === currentRoundId) || rounds[0];
  return (
    <div className={styles.rounds}>
      <div className={styles.title}>
        {isMobile ? (
          <CapsuleTabs
            tabs={visibleRounds}
            value={currentRoundId!}
            onChange={setCurrentRoundId}
          />
        ) : (
          <PageTabs
            tabClassName={styles.title}
            tabs={visibleRounds}
            value={currentRoundId!}
            onChange={setCurrentRoundId}
            inverted
          />
        )}
      </div>

      <RoundResult
        key={currentRound?.roundId}
        round={currentRound}
        onGameClick={onGameClick}
      />
    </div>
  );
}

type RoundResultProps = {
  round?: KnockoutResultsRound;
  onGameClick?: (gameId: string) => void;
};

function RoundResult({ round, onGameClick }: RoundResultProps) {
  if (!round) return null;

  return (
    <div className={styles.round}>
      {round.matches.map((m) => (
        <BracketsMatch
          className={styles.match}
          key={'match' + m.player1?.userId + m.player2?.userId}
          player1={m.player1}
          player2={m.player2}
          isFinal={round.roundType === 'Final'}
          onGameClick={onGameClick}
        />
      ))}
    </div>
  );
}
