import { useRef } from 'react';
import { RoundSocketEvents } from '@features/game/api/clients';
import { useRoundSocket } from '@features/game/api/hooks';
import { SocketInternalEvents } from '@libs/socket';
import { TOURNAMENT_STREAM_SOCKET_URL } from './clients';

/*
    ____,-------------------------------,____
    \   |            Sockets            |   /
    /___|-------------------------------|___\
*/

// ------------------------------------------
// Tournament Round Games

export type UseTournamentGamesSocketEvents =
  | RoundSocketEvents
  | SocketInternalEvents
  // HACK: синтетическое событие, если в раунд добавились новые партии (доп.
  // партии)
  // FIXME: удалить, когда на сервер реализует нормальное событие (игра
  // создана) + начнёт возвращать NotStarted партии в ручках с результатами,
  // либо когда для всех доп. партий будет создаваться отдельный раунд
  | { eventType: 'NewGameStarted' };

export function useTournamentGamesSocket({
  tournamentId,
  roundNumber,
  cb,
  enabled = true,
}: {
  tournamentId: string;
  roundNumber: number;
  cb: (e: UseTournamentGamesSocketEvents) => void;
  enabled?: boolean;
}) {
  const seenGames = useRef(new Set());

  return useRoundSocket({
    tournamentId,
    roundNumber,
    cb: (e) => {
      const ids = seenGames.current;
      if (e.eventType === 'RoundGamesState') {
        e.data.forEach((g) => ids.add(g.id));
      } else if (e.eventType === 'GameChanged') {
        // HACK: проверка статуса нужна т.к. в ручки результатов турнира (для
        // которых нужно это событие) не прилетают NotStarted партии
        if (!ids.has(e.gameId) && e.currentState.status === 'InProgress') {
          ids.add(e.gameId);
          cb({ eventType: 'NewGameStarted' });
        }
      }
      cb(e);
    },
    customSocketUrl: TOURNAMENT_STREAM_SOCKET_URL,
    enabled,
  });
}
