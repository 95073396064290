import { addHours } from 'date-fns/addHours';
import {
  ClubGroup,
  GameMovementRow,
  MemberDto,
  MemberTournamentParticipantDto,
  ResultsTableAdditionalScore,
  ResultsTableData,
  ResultsGame,
  ResultsTableParticipant,
  ResultsTableRound,
  Tournament,
  KnockoutResults,
  KnockoutResultsMatch,
  KnockoutResultsPlayer,
} from '@features/shared/api/typings';

export const participantGroups: ClubGroup[] = [
  {
    id: 1,
    title: 'Юность Москвы',
    participantIds: [1, 2, 3],
  },
  {
    id: 2,
    title: 'Старость Омской Области',
    participantIds: [4, 5, 6],
  },
  {
    id: 3,
    title: 'Воронежский Кризис Среднего Возраста',
    participantIds: [7, 8, 9],
  },
];

const members: MemberDto[] = [
  {
    userId: '1',
    firstName: 'Константин',
    lastName: 'Константинопольский',
    title: 'IG',
    email: 'kostya@test.ru',
    rcfId: '314159265',
    status: 'Active',
    joinTimestampMs: 123123,
    tournamentsCount: 0,
    gamesCount: 0,
    elo: {
      blitz: {
        clockControl: 'Blitz',
        gamesCount: 15,
        rating: 2412,
        lastChange: 123,
      },
      rapid: {
        clockControl: 'Rapid',
        gamesCount: 15,
        lastChange: 123,
        rating: 1532,
      },
      classic: {
        clockControl: 'Classic',
        gamesCount: 15,
        lastChange: 123,
        rating: 1111,
      },
    },
  },
  {
    userId: '2',
    firstName: 'Иван',
    lastName: 'Петров',
    title: 'GM',
    rcfId: '123',
    email: 'ivan@test.ru',
    status: 'Active',
    joinTimestampMs: 123123,
    tournamentsCount: 12,
    gamesCount: 9999,
    elo: {
      blitz: {
        clockControl: 'Blitz',
        gamesCount: 15,
        rating: 1996,
        lastChange: 123,
      },
      rapid: {
        clockControl: 'Rapid',
        gamesCount: 15,
        lastChange: 123,
        rating: 1840,
      },
      classic: {
        clockControl: 'Classic',
        gamesCount: 15,
        lastChange: 123,
        rating: 2000,
      },
    },
  },
  {
    userId: '3',
    firstName: 'Магнус',
    lastName: 'Степанович Курский',
    title: 'FM',
    email: 'magnus@test.ru',
    rcfId: '92653589',
    status: 'Active',
    joinTimestampMs: 123123,
    tournamentsCount: 9999,
    gamesCount: 9999,
    elo: {
      blitz: {
        clockControl: 'Blitz',
        gamesCount: 15,
        rating: 2412,
        lastChange: 123,
      },
      rapid: {
        clockControl: 'Rapid',
        gamesCount: 15,
        lastChange: 123,
        rating: 1622,
      },
      classic: {
        clockControl: 'Classic',
        gamesCount: 15,
        lastChange: 123,
        rating: 3000,
      },
    },
  },
];

export const memberTournamentParticipants: MemberTournamentParticipantDto[] =
  members.map((p) => ({
    ...p,
    participationStatus: 'Invited',
  }));

export const gameEvents: Tournament[] = [
  {
    clubId: '200',
    tournamentId: '1',
    title: 'Отборочный турнир отдела закупок',
    status: 'InProgress',
    system: 'Swiss',
    swissSettings: {
      roundsCount: 2,
    },
    logoUrl:
      'https://upload.wikimedia.org/wikipedia/ru/2/2d/Gazprom-Logo-rus.svg',
    entrance: 'Invite',
    rcfRankCalculated: true,
    promoted: false,
    mode: 'Private',
    clockControl: 'Blitz',
    clockControlSettingsPlayer1: {
      initialSeconds: 5,
      addSecondsPerMove: 3,
    },
    clockControlSettingsPlayer2: {
      initialSeconds: 5,
      addSecondsPerMove: 3,
    },
    additionalScoreOrder: [],
    rulesUrl: '',
    contacts: [
      {
        name: 'Судья',
        label: 'Константин Константинопольский',
        uri: 'http://tg.me/kost',
      },
    ],
    startTimestampMs: addHours(Date.now(), -1).getTime(),
    rounds: [
      {
        roundId: '1',
        roundNumber: 1,
        cycleNumber: 1,
        status: 'Finished',
        startTimestampMs: new Date().getTime(),
      },
      {
        roundId: '2',
        roundNumber: 2,
        cycleNumber: 1,
        status: 'Drafted',
        startTimestampMs: new Date().getTime() + 120 * 1000,
      },
      {
        roundId: '3',
        roundNumber: 3,
        cycleNumber: 1,
        status: 'New',
        startTimestampMs: new Date().getTime() + 120 * 1000,
      },
    ],
    participantsCount: 99,
    delayBetweenRoundsSeconds: 180,
  },
  {
    clubId: '200',
    tournamentId: '2',
    title: 'Отборочный турнир отдела закупок',
    status: 'Paused',
    system: 'Swiss',
    swissSettings: {
      roundsCount: 2,
    },
    entrance: 'Invite',
    rcfRankCalculated: true,
    promoted: false,
    mode: 'Private',
    clockControl: 'Blitz',
    clockControlSettingsPlayer1: {
      initialSeconds: 5,
      addSecondsPerMove: 3,
    },
    clockControlSettingsPlayer2: {
      initialSeconds: 5,
      addSecondsPerMove: 3,
    },
    additionalScoreOrder: [],
    rulesUrl: '',
    contacts: [],
    startTimestampMs: addHours(Date.now(), -1).getTime(),
    pausedTillTimestampMs: addHours(Date.now(), 1).getTime(),
    rounds: [
      {
        roundId: '1',
        roundNumber: 1,
        cycleNumber: 1,
        status: 'InProgress',
        startTimestampMs: new Date().getTime(),
      },
    ],
    participantsCount: 99,
    delayBetweenRoundsSeconds: 180,
  },
  {
    clubId: '200',
    tournamentId: '3',
    title: 'Отборочный турнир отдела закупок. Второй тур.',
    status: 'Finished',
    system: 'Knockout',
    knockOutSettings: {
      gamesPerRound: 2,
      gamesPerFinalRound: 2,
      tieSettings: {
        additionalGamesCount: 2,
        additionalGamesFinalCount: 4,
        clockControl: 'Rapid',
        clockControlSettingsPlayer1: {
          initialSeconds: 300,
          addSecondsPerMove: 3,
        },
        clockControlSettingsPlayer2: {
          initialSeconds: 300,
          addSecondsPerMove: 3,
        },
      },
      armageddonSettings: {
        clockControl: 'Rapid',
        clockControlSettingsPlayer1: {
          initialSeconds: 300,
          addSecondsPerMove: 3,
        },
        clockControlSettingsPlayer2: {
          initialSeconds: 300,
          addSecondsPerMove: 3,
        },
      },
    },
    entrance: 'Invite',
    rcfRankCalculated: true,
    promoted: false,
    mode: 'PrivateTeam',
    clockControl: 'Rapid',
    clockControlSettingsPlayer1: {
      initialSeconds: 5,
      addSecondsPerMove: 3,
    },
    clockControlSettingsPlayer2: {
      initialSeconds: 5,
      addSecondsPerMove: 3,
    },
    additionalScoreOrder: ['Berger', 'PartBerger'],
    rulesUrl: '',
    contacts: [],
    startTimestampMs: addHours(Date.now(), -10).getTime(),
    rounds: [],
    participantsCount: 99,
    delayBetweenRoundsSeconds: 180,
  },
  {
    clubId: '200',
    tournamentId: '4',
    title: 'Отборочный турнир отдела закупок. Третий тур.',
    status: 'Finished',
    system: 'RoundRobin',
    roundRobinSettings: {
      roundsCount: 2,
    },
    entrance: 'Rank',
    rcfRankCalculated: true,
    promoted: true,
    mode: 'Team',
    clockControl: 'Classic',
    clockControlSettingsPlayer1: {
      initialSeconds: 60,
      addSecondsPerMove: 60,
    },
    clockControlSettingsPlayer2: {
      initialSeconds: 60,
      addSecondsPerMove: 60,
    },
    additionalScoreOrder: [],
    rulesUrl: '',
    contacts: [],
    startTimestampMs: addHours(Date.now(), -100).getTime(),
    rounds: [],
    participantsCount: 99,
    delayBetweenRoundsSeconds: 180,
  },
  {
    clubId: '200',
    tournamentId: '5',
    title: 'Новый турнир',
    status: 'Draft',
    system: 'RoundRobin',
    roundRobinSettings: {
      roundsCount: 4,
    },
    entrance: 'Invite',
    rcfRankCalculated: false,
    promoted: false,
    mode: 'Private',
    clockControl: 'Blitz',
    clockControlSettingsPlayer1: {
      initialSeconds: 2,
      addSecondsPerMove: 3,
    },
    clockControlSettingsPlayer2: {
      initialSeconds: 2,
      addSecondsPerMove: 3,
    },
    additionalScoreOrder: [],
    rulesUrl: '',
    contacts: [],
    startTimestampMs: addHours(Date.now(), 2).getTime(),
    rounds: [],
    participantsCount: 99,
    delayBetweenRoundsSeconds: 180,
  },
  {
    clubId: '200',
    tournamentId: '6',
    title: 'Новый турнир',
    status: 'Draft',
    system: 'RoundRobin',
    roundRobinSettings: {
      roundsCount: 4,
    },
    entrance: 'Invite',
    rcfRankCalculated: false,
    promoted: false,
    mode: 'Private',
    clockControl: 'Blitz',
    clockControlSettingsPlayer1: {
      initialSeconds: 2,
      addSecondsPerMove: 3,
    },
    clockControlSettingsPlayer2: {
      initialSeconds: 2,
      addSecondsPerMove: 3,
    },
    additionalScoreOrder: [],
    rulesUrl: '',
    contacts: [],
    rounds: [],
    participantsCount: 99,
    delayBetweenRoundsSeconds: 180,
  },
];

export const clubs = [
  {
    clubId: '1',
    title: 'Шахматный клуб ОАО «Газпром»',
    description:
      'Armageddon Championship Series: Women’s Week — это&nbsp;захватывающее ежегодное событие для любителей шахмат посвященное выдающимся женщинам в мире шахмат. Мероприятие проходит в живописной Испании и приглашает самых ярких и талантливых шахматисток со всего мира на&nbsp;трехдневные состязания',
    logo: 'https://upload.wikimedia.org/wikipedia/ru/2/2d/Gazprom-Logo-rus.svg',
    participantsCount: 3141,
    tournaments: '5 · 92',
    createdAt: '2023-03-02',
    admin: 'Константин Константинопольский',
    tg: '@gazchess',
    events: gameEvents,
    participants: members,
    memberStatus: 'Active' as const,
    createdTimestampMs: addHours(Date.now(), -10).getTime(),
  },
];

export const resultsTableAdditionalScores: ResultsTableAdditionalScore[] = [
  {
    scoreType: 'Berger',
    score: 4,
  },
  {
    scoreType: 'PartBerger',
    score: 6.5,
  },
];
export const resultsTableGame: ResultsGame[] = [
  {
    // score: ,
    resultType: 'Skipped',
  },
  {
    score: 1,
    resultType: 'Win',
    side: 'Black',
  },
  {
    score: 0,
    resultType: 'Loss',
    side: 'Black',
  },
  {
    // score: 'С',
    resultType: 'InProgress',
    side: 'Both',
  },
  {
    score: 0.5,
    resultType: 'Draw',
    side: 'White',
  },
  {
    //score: '',
    resultType: 'UnPlayed',
  },
  {
    score: 1,
    resultType: 'Loss',
    side: 'White',
  },
];

export const resultsTablePeople: ResultsTableParticipant[] = [
  {
    no: 1,
    title: 'Константин Константинопольский',
    games: resultsTableGame,
    additionalScores: resultsTableAdditionalScores,
    totalScore: 20,
    place: '2',
  },
  {
    no: 2,
    title: 'Александр Александрийский',
    games: resultsTableGame,
    additionalScores: resultsTableAdditionalScores,
    totalScore: 24.5,
    place: '1',
  },
  {
    no: 3,
    title: 'Петр Петрович',
    games: resultsTableGame,
    additionalScores: resultsTableAdditionalScores,
    totalScore: 10,
    place: '4',
  },
  {
    no: 4,
    title: 'Сергей Сергеин',
    games: resultsTableGame,
    additionalScores: resultsTableAdditionalScores,
    totalScore: 0,
    place: '7',
  },
  {
    no: 5,
    title: 'Пятачок',
    games: resultsTableGame,
    additionalScores: resultsTableAdditionalScores,
    totalScore: 1,
    place: '6',
  },
  {
    no: 6,
    title: 'Винни-пух',
    games: resultsTableGame,
    additionalScores: resultsTableAdditionalScores,
    totalScore: 5,
    place: '5',
  },
  {
    no: 7,
    title: 'Леонид Аркадиевич',
    games: resultsTableGame,
    additionalScores: resultsTableAdditionalScores,
    totalScore: 15,
    place: '3',
  },
];

export const resultsTableRounds: ResultsTableRound[] = [
  {
    num: 1,
    members: resultsTablePeople,
    gamesCount: 7,
    additionalScoreTypes: ['Berger', 'PartBerger'],
  },
  {
    num: 2,
    members: resultsTablePeople,
    gamesCount: 7,
    additionalScoreTypes: ['Berger', 'PartBerger'],
  },
];

export const resultsTableParticipantGroups: ResultsTableParticipant[] = [
  {
    no: 1,
    title: 'Юность Москвы',
    games: resultsTableGame,
    additionalScores: resultsTableAdditionalScores,
    totalScore: 20,
    place: '2',
    subRows: resultsTablePeople,
  },
  {
    no: 2,
    title: 'Дряхлость Омской Области',
    games: resultsTableGame,
    additionalScores: resultsTableAdditionalScores,
    totalScore: 24.5,
    place: '1',
    subRows: resultsTablePeople,
  },
  {
    no: 3,
    title: 'Воронежский Кризис Среднего Возраста',
    games: resultsTableGame,
    additionalScores: resultsTableAdditionalScores,
    totalScore: 10,
    place: '4',
    subRows: resultsTablePeople,
  },
  {
    no: 4,
    title: 'Младенчество Калининград',
    games: resultsTableGame,
    additionalScores: resultsTableAdditionalScores,
    totalScore: 12,
    place: '3',
    subRows: resultsTablePeople,
  },
];

export const groupedResultsTableRounds: ResultsTableRound[] = [
  {
    num: 1,
    members: resultsTableParticipantGroups,
    gamesCount: 7,
    additionalScoreTypes: ['PartBerger', 'Black'],
  },
];

export const resultsTableData: ResultsTableData = {
  rounds: resultsTableRounds,
};

export const resultsTableData2: ResultsTableData = {
  rounds: [
    ...resultsTableRounds,
    resultsTableRounds[0]!,
    resultsTableRounds[1]!,
  ],
};

export const resultsTableData3: ResultsTableData = {
  rounds: groupedResultsTableRounds,
};

export const roundMovementRows: GameMovementRow[] = [
  {
    boardNo: 1,
    player1: {
      firstName: 'Константин',
      lastName: 'Константинопольский',
      title: 'GM',
      userId: '1',
      game: {
        gameId: '1',
        score: 1,
        side: 'White',
        resultType: 'Win',
      },
    },
    player2: {
      firstName: 'Александр',
      lastName: 'Александрийский',
      title: 'IG',
      userId: '2',
      game: {
        gameId: '1',
        score: 0,
        side: 'Black',
        resultType: 'Loss',
      },
    },
  },
  {
    boardNo: 2,
    player1: {
      firstName: 'Винни',
      lastName: 'Пу',
      title: 'Noob',
      userId: '3',
      game: {
        gameId: '2',
        score: 0.5,
        side: 'Both',
        resultType: 'Draw',
      },
    },
    player2: {
      firstName: 'Пятачок',
      lastName: 'Просто свинья',
      title: 'Хрюн',
      userId: '4',
      game: {
        gameId: '2',
        score: 0.5,
        side: 'Both',
        resultType: 'Draw',
      },
    },
  },
];

const knockoutGame1: ResultsGame = {
  gameId: '1',
  score: 1,
  side: 'White',
  resultType: 'Win',
};
const knockoutGame2: ResultsGame = {
  gameId: '2',
  score: 0,
  side: 'Black',
  resultType: 'Loss',
};
const knockoutPlayer1 = {
  userId: '1',
  lastName: 'Константинопольский',
  firstName: 'Константин',
};
const knockoutPlayer2 = {
  userId: '2',
  firstName: 'Вася',
  lastName: 'Пупкин',
};
const knockoutTotal1: KnockoutResultsPlayer['total'] = {
  score: '2.5',
  resultType: 'Win',
};
const knockoutTotal2: KnockoutResultsPlayer['total'] = {
  score: '1',
  resultType: 'Loss',
};
const knockoutMatch1: KnockoutResultsMatch = {
  player1: {
    ...knockoutPlayer1,
    total: knockoutTotal1,
    games: [knockoutGame1],
  },
  player2: {
    ...knockoutPlayer2,
    total: knockoutTotal2,
    games: [knockoutGame2],
  },
};
const knockoutMatch2: KnockoutResultsMatch = {
  player1: {
    ...knockoutPlayer1,
    total: knockoutTotal1,
    games: [knockoutGame1, knockoutGame2],
  },
  player2: {
    ...knockoutPlayer2,
    total: knockoutTotal2,
    games: [knockoutGame2, knockoutGame1],
  },
};
const knockoutMatchBye: KnockoutResultsMatch = {
  player1: {
    ...knockoutPlayer1,
    total: { score: '', resultType: 'UnPlayed' },
    games: [],
  },
};
const knockoutMatchFinal: KnockoutResultsMatch = {
  player1: {
    ...knockoutPlayer1,
    total: knockoutTotal1,
    games: [knockoutGame1],
  },
  player2: {
    ...knockoutPlayer2,
    total: knockoutTotal2,
    games: [knockoutGame2],
  },
};

export const knockoutResultsData: KnockoutResults = {
  rounds: [
    {
      roundId: '1',
      roundName: '32',
      roundType: 'WinnerBranch',
      matches: [
        knockoutMatch1,
        knockoutMatch2,
        knockoutMatch1,
        knockoutMatch2,
        knockoutMatchBye,
      ],
    },
    {
      roundId: '2',
      roundName: '16',
      roundType: 'WinnerBranch',
      matches: [knockoutMatch1, knockoutMatch2, knockoutMatchBye],
    },
    {
      roundId: '3',
      roundName: '2',
      roundType: 'WinnerBranch',
      matches: [knockoutMatch1, knockoutMatchBye],
    },
    {
      roundId: '4',
      roundName: '3e место',
      roundType: 'ThirdPlace',
      matches: [knockoutMatch1],
    },
    {
      roundId: '5',
      roundName: '1',
      roundType: 'Final',
      matches: [knockoutMatchFinal],
    },
  ],
};

const knockoutTeam1 = {
  userId: '',
  lastName: '',
  firstName: 'Константиновские',
};
const knockoutTeam2 = {
  userId: '',
  firstName: 'Пупкины',
  lastName: '',
};
const knockoutTeamMatch1: KnockoutResultsMatch = {
  player1: {
    ...knockoutTeam1,
    total: knockoutTotal1,
    games: [knockoutGame1],
    subRows: [
      {
        ...knockoutPlayer1,
        total: knockoutTotal1,
        games: [knockoutGame1],
      },
    ],
  },
  player2: {
    ...knockoutTeam2,
    total: knockoutTotal2,
    games: [knockoutGame2],
    subRows: [
      {
        ...knockoutTeam2,
        total: knockoutTotal2,
        games: [knockoutGame2],
      },
    ],
  },
};
const knockoutTeamMatch2: KnockoutResultsMatch = {
  player1: {
    ...knockoutTeam1,
    total: knockoutTotal1,
    games: [knockoutGame1, knockoutGame2],
    subRows: [
      {
        ...knockoutPlayer1,
        total: knockoutTotal1,
        games: [knockoutGame1, knockoutGame2],
      },
    ],
  },
  player2: {
    ...knockoutTeam2,
    total: knockoutTotal2,
    games: [knockoutGame2, knockoutGame1],
    subRows: [
      {
        ...knockoutPlayer2,
        total: knockoutTotal2,
        games: [knockoutGame2, knockoutGame1],
      },
    ],
  },
};
const knockoutTeamMatchBye: KnockoutResultsMatch = {
  player1: {
    ...knockoutTeam1,
    total: { score: '', resultType: 'UnPlayed' },
    games: [],
    subRows: [
      {
        ...knockoutPlayer1,
        total: { score: '', resultType: 'UnPlayed' },
        games: [],
      },
    ],
  },
};
const knockoutTeamMatchFinal: KnockoutResultsMatch = {
  player1: {
    ...knockoutTeam1,
    total: knockoutTotal1,
    games: [knockoutGame1],
    subRows: [
      {
        ...knockoutPlayer1,
        total: knockoutTotal1,
        games: [knockoutGame1],
      },
    ],
  },
  player2: {
    ...knockoutTeam2,
    total: knockoutTotal2,
    games: [knockoutGame2],
    subRows: [
      {
        ...knockoutPlayer2,
        total: knockoutTotal2,
        games: [knockoutGame2],
      },
    ],
  },
};

export const knockoutTeamResultsData: KnockoutResults = {
  rounds: [
    {
      roundId: '1',
      roundName: '32',
      roundType: 'WinnerBranch',
      matches: [
        knockoutTeamMatch1,
        knockoutTeamMatch2,
        knockoutTeamMatch1,
        knockoutTeamMatch2,
        knockoutTeamMatchBye,
      ],
    },
    {
      roundId: '2',
      roundName: '16',
      roundType: 'WinnerBranch',
      matches: [knockoutTeamMatch1, knockoutTeamMatch2, knockoutTeamMatchBye],
    },
    {
      roundId: '3',
      roundName: '2',
      roundType: 'WinnerBranch',
      matches: [knockoutTeamMatch1, knockoutTeamMatchBye],
    },
    {
      roundId: '4',
      roundName: 'третье место',
      roundType: 'ThirdPlace',
      matches: [knockoutTeamMatch1],
    },
    {
      roundId: '5',
      roundName: '1',
      roundType: 'Final',
      matches: [knockoutTeamMatchFinal],
    },
  ],
};
