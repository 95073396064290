import { Chess } from 'chess.js';
import { FEN_STARTING_POSITION } from './constants';
import { IPgnMove } from './pgn';

export function movesToFen(
  initialFen: string = FEN_STARTING_POSITION,
  moves: IPgnMove[] = [],
) {
  const chess = new Chess(initialFen);
  moves.forEach((m) => {
    chess.move(m.san);
  });
  const h = chess.history({ verbose: true });
  const m = h[h.length - 1];
  const { from, to } = m ?? {};
  const fen = chess.fen();
  return { fen, from, to };
}
