import { RefObject, useEffect } from 'react';

type Options = {
  attributes: boolean;
  childList: boolean;
  subtree: boolean;
};

export function useMutationObserver(
  ref: RefObject<Element>,
  callback: () => void,
  options: Options = {
    attributes: true,
    childList: true,
    subtree: true,
  },
) {
  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(callback);
      observer.observe(ref.current, options);
      return () => observer.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, callback]);
}
