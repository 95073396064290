import { ReactNode, useCallback, useState } from 'react';
import {
  GameParticipantsResult,
  ResultsGame,
} from '@features/shared/api/typings';
import { GameResultsBadge } from '@features/tournament/ui/components/GameResultBadge';
import { cx } from '@libs/classnames';
import { CollapseIcon } from '@ui/components/CollapseIcon';
import { Fraction } from '@ui/components/Fraction';
import styles from './BracketsMatch.module.css';

type Player = {
  userId: string;
  lastName: string;
  firstName: string;
  games: ResultsGame[];
  total: {
    score: string;
    resultType: GameParticipantsResult;
  };
  subRows?: Player[];
};

export type BracketsMatchProps = {
  className?: string;
  roundName?: ReactNode;
  player1?: Player;
  player2?: Player;
  isFinal?: boolean;
  onGameClick?: (gameId: string) => void;
};

export function BracketsMatch({
  className,
  roundName,
  player1,
  player2,
  isFinal,
  onGameClick,
}: BracketsMatchProps) {
  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  return (
    <div className={cx(styles.match, className)}>
      {roundName && <div className={styles.roundName}>{roundName}</div>}
      <BracketsMatchPlayer
        name={player1 ? player1.lastName + ' ' + player1.firstName : 'Bye'}
        games={player1?.games || []}
        totalScore={player1?.total.score || 'Bye'}
        totalResult={player1?.total.resultType || 'Loss'}
        isFinal={isFinal}
        onGameClick={onGameClick}
        subRows={player1?.subRows}
        isOpen={open}
        toggle={toggle}
      />
      {open &&
        player1?.subRows &&
        player1.subRows.map((p, i) => (
          <BracketsMatchPlayer
            key={p.lastName + p.firstName + i}
            name={p ? p.lastName + ' ' + p.firstName : 'Bye'}
            games={p?.games || []}
            totalScore={p?.total.score || 'Bye'}
            totalResult={p?.total.resultType || 'Loss'}
            isFinal={isFinal}
            onGameClick={onGameClick}
            isSubRow
          />
        ))}

      <BracketsMatchPlayer
        name={player2 ? player2.lastName + ' ' + player2.firstName : 'Bye'}
        games={player2?.games || []}
        totalScore={player2?.total.score || ''}
        totalResult={player2?.total.resultType || 'Loss'}
        isFinal={isFinal}
        onGameClick={onGameClick}
        subRows={player2?.subRows}
        isOpen={open}
        toggle={toggle}
      />

      {open &&
        player2?.subRows &&
        player2.subRows.map((p, i) => (
          <BracketsMatchPlayer
            key={p.lastName + p.firstName + i}
            name={p ? p.lastName + ' ' + p.firstName : 'Bye'}
            games={p?.games || []}
            totalScore={p?.total.score || 'Bye'}
            totalResult={p?.total.resultType || 'Loss'}
            isFinal={isFinal}
            onGameClick={onGameClick}
            isSubRow
          />
        ))}
    </div>
  );
}

type BracketsMatchPlayerProps = {
  name: string;
  games: ResultsGame[];
  totalScore: string;
  totalResult: GameParticipantsResult;
  isFinal?: boolean;
  onGameClick?: (gameId: string) => void;
  subRows?: Player[];
  isOpen?: boolean;
  toggle?: () => void;
  isSubRow?: boolean;
};

function BracketsMatchPlayer({
  name,
  games,
  totalScore,
  totalResult,
  isFinal,
  onGameClick,
  subRows,
  isOpen,
  toggle,
  isSubRow,
}: BracketsMatchPlayerProps) {
  const isGroup = !!subRows;

  return (
    <div
      className={cx(styles.playerMatch, {
        [styles.loss]: totalResult === 'Loss',
        [styles.win]: totalResult === 'Win',
        [styles.final]: isFinal,
        [styles.group]: isGroup,
        [styles.subRow]: isSubRow,
      })}
    >
      <div className={styles.playerName} onClick={toggle}>
        {name}
      </div>

      <div className={styles.results}>
        {isGroup && (
          <CollapseIcon
            className={styles.collapseIcon}
            toggled={isOpen}
            onClick={toggle}
          />
        )}

        {games.map((g) => (
          <GameResultsBadge
            className={cx(styles.gameResultsBadge, {
              [styles.win]: g.resultType === 'Win',
            })}
            key={g.gameId}
            gameId={g.gameId}
            result={g.resultType}
            score={g.score}
            side={g.side}
            dotCentered
            onGameClick={onGameClick}
          />
        ))}

        <div className={styles.totalScore}>
          {totalResult === 'UnPlayed' ? 'Bye' : <Fraction value={totalScore} />}
        </div>
      </div>
    </div>
  );
}
