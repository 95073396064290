import { IPgnMove } from '@libs/chess/pgn';

export const EMPTY_MODEL: IModelMain = { type: 'main', count: 0, moves: [] };

/*
    ____,-------------------------------,____
    \   |            Модель             |   /
    /___|-------------------------------|___\
*/

// Основная ветка
//
export interface IModelMain {
  type: 'main';
  count: number;
  moves: IModelFullMove[];
  moveWithComment?: IPgnMove; // комментарий всей ветки
}

// Ходы основной ветки (full move)
//
export interface IModelFullMove {
  type: 'full-move';
  white: IModelStub | IModelHalfMove;
  black: IModelStub | IModelHalfMove | undefined;
  branches?: IModelVariant[];
}

// Варианты
//
export interface IModelVariant {
  type: 'variant';
  level: number;
  entries: (IModelBlock | IModelVariant)[];
  moveWithComment?: IPgnMove; // комментарий всей ветки
  complex?: boolean; // нельзя инлайнить (т.к. имеет ветвистую вложенность подвариантов)
  branches?: IModelVariant[]; // дерево
}

// Последовательности полуходов
//
export interface IModelBlock {
  type: 'block';
  moves: (IModelHalfMove | IModelStub)[];
}

// Заглушка ходы (троеточие)
//
export interface IModelStub {
  type: 'stub';
  move: {
    san: '...';
    color: 'black' | 'white';
    moveFull: number;
    comment?: undefined;
    nags?: number[];
  };
}

// Полуход (half move)
//
export interface IModelHalfMove {
  type: 'move';
  move: IPgnMove;
}

/*
    ____,-------------------------------,____
    \   |            Маппинг            |   /
    /___|-------------------------------|___\
*/

// Основная ветка.
//
// Правила:
// - ход разбивается, если у белых есть альтернативные ветки
// - ход разбивается, если у белых есть комментарий
// - ход может начинаться с чёрных, тогда вместо белых — stub
export function mapModel(entries: IPgnMove[], allowInline = true): IModelMain {
  const moves: IModelFullMove[] = [];
  let comment: IPgnMove | undefined;

  let i = 0;
  while (i < entries.length) {
    const m = entries[i]!;
    const w = m.color === 'white' ? m : undefined;
    const b = m.color === 'black' ? m : entries[i + 1];
    const mustBreak = w?.alts || w?.comment;

    // pgn main comment

    const isFirst = i === 0;
    if (isFirst && m.commentBefore !== undefined) {
      comment = m;
    }

    // moves

    const white: IModelHalfMove | IModelStub = w
      ? { type: 'move', move: w }
      : {
          type: 'stub',
          move: { color: 'white', moveFull: m.moveFull, san: '...' },
        };

    const black: IModelHalfMove | IModelStub | undefined = b
      ? mustBreak
        ? {
            type: 'stub',
            move: { color: 'black', moveFull: b.moveFull, san: '...' },
          }
        : { type: 'move', move: b }
      : undefined;

    // alternatives

    const alts = mustBreak ? w?.alts : b?.alts;
    const branches: IModelVariant[] | undefined = alts?.map((a) =>
      mapVariant(a, 1, allowInline),
    );

    // full move

    const fullMove: IModelFullMove = {
      type: 'full-move',
      white,
      black,
      branches,
    };

    moves.push(fullMove);

    // increment

    const increment = white.type === 'move' && black?.type === 'move' ? 2 : 1;
    i += increment;
  }

  return {
    type: 'main',
    count: entries[entries.length - 1]?.moveFull ?? 0,
    moves,
    moveWithComment: comment,
  };
}

// Побочные ветки.
//
// Правила:
// - ветка, включающая больше 1 подветки — сложная, её нельзя инлафнить
// - ветка, включающая служную подветку — тоже сложная
// - простые ветки инлайним
// - сложные ветки показываем как дерево
// - сложная ветка разбивается на две при первой сложной подветке: начало и
//   хвост; хвост оригинальной ветки отображается в дереве как первая подветка
function mapVariant(
  entries: IPgnMove[],
  level: number,
  allowInline = true,
): IModelVariant {
  const blocks: IModelVariant['entries'] = [];
  let moves: IModelBlock['moves'] = [];
  let variantComment: IPgnMove | undefined;
  let complex = false;
  let branches: IModelVariant[] | undefined;

  const flush = () => {
    if (!moves.length) return;
    blocks.push({ type: 'block', moves });
    moves = [];
  };

  entries.forEach((m, i) => {
    if (branches) return;

    const isBlack = m.color === 'black';
    const isWhite = !isBlack;
    const isFirst = i === 0;
    const notLast = i < entries.length - 1;

    if (m.commentBefore !== undefined) {
      variantComment = m;
    }

    if (isFirst && isBlack) {
      moves.push({
        type: 'stub',
        move: { color: 'white', moveFull: m.moveFull, san: '...' },
      });
    }

    // проверка isFirst нужна для рекурсивного вызова при парсинге веток. Считаем, что у первого хода варианта не может быть аоттернатив (т.к. парсер
    // поднимет их вверх по ирархии вплоть до fullmove, где рекурсия не страшна)
    const alts = isFirst
      ? undefined
      : m.alts?.map((a) => mapVariant(a, level + 1, allowInline));
    const needBranches =
      alts && (!allowInline || alts.length > 1 || alts.find((a) => a.complex));
    if (needBranches) {
      complex = true;
    }

    if (!needBranches) {
      moves.push({ type: 'move', move: m });
    } else {
      branches = [];
      branches.push(mapVariant(entries.slice(i), level + 1, allowInline));
      branches.push(...alts);
    }

    const mustStub = alts && !branches && isWhite && notLast;

    if (mustStub) {
      moves.push({
        type: 'stub',
        move: { color: 'black', moveFull: m.moveFull, san: '...' },
      });
    }

    if (alts) {
      flush();
    }

    if (!branches && alts) blocks.push(...alts);

    if (mustStub) {
      moves.push({
        type: 'stub',
        move: { color: 'white', moveFull: m.moveFull, san: '...' },
      });
    }
  });

  flush();

  return {
    type: 'variant',
    level,
    entries: blocks,
    moveWithComment: variantComment,
    complex,
    branches,
  };
}

/*
    ____,-------------------------------,____
    \   |      Редактирвоание ходов     |   /
    /___|-------------------------------|___\
*/

type MovePosition = {
  variant: IPgnMove[];
  index: number;
  next?: IPgnMove;
  prev?: IPgnMove | null;
  path: { parent: IPgnMove; branch: IPgnMove[] }[];
  playSequence: IPgnMove[];
};

type Action =
  | UpdateMoveAction
  | AddMoveAction
  | DeleteMoveAction
  | PromoteVariantAction;

// Изменить аттрибуты хода
//
type UpdateMoveAction = {
  action: 'update';
  data: Partial<Omit<IPgnMove, 'alts'>>;
};

// Сделать новый ход из текущей позиции
//
type AddMoveAction = {
  action: 'add';
  readonlyMainVariant?: boolean;
  san: string;
};

// Удалить ход
//
// ВАЖНО: Удаление первой ветки МОДЕЛИ ведёт к удалению всех веток из-за
// того, что это на самом деле это не подветка, а хвост основной ветки, а её
// соседи — на самом деле её варианты.
// Для таких веток вместо delete следует использовать promote
type DeleteMoveAction = {
  action: 'delete';
};

// Удалить ход и заменить его одним из его вариантов
//
// ВАЖНО: см. комментарий к DeleteMoveAction
type PromoteVariantAction = {
  action: 'promote';
  variant: number;
};

export function updateMoves(
  move: IPgnMove,
  moves: IPgnMove[],
  action: Action,
): { nextMoves: IPgnMove[]; nextSelected?: IPgnMove } {
  let nextMoves = [...moves];
  let nextSelected: IPgnMove | undefined = move;

  const position = findMovePosition(move, moves);
  const path = position?.path;
  if (!path) {
    console.error('PgnEditor: updateMove: Move not found', {
      action,
      move,
      moves,
    });
    return { nextMoves, nextSelected };
  }

  let i = 0;
  let prevParent: IPgnMove | undefined;
  let parent = path[i]?.parent;
  let variant = nextMoves;
  let branch = path[i]?.branch;
  while (parent && branch) {
    const nextVariant = [...branch];
    const nextParent = {
      ...parent,
      alts: parent.alts?.map((a) => (a === branch ? nextVariant : a)),
    };

    variant.splice(variant.indexOf(parent), 1, nextParent);

    i++;
    parent = path[i]?.parent;
    prevParent = nextParent;
    variant = nextVariant;
    branch = path[i]?.branch;
  }

  const index = variant.indexOf(move);

  switch (action.action) {
    case 'update':
      nextSelected = { ...move, ...action.data };
      variant.splice(index, 1, nextSelected);
      break;
    case 'add': {
      // проверяем дубликаты
      const same = getSimilarAlt(
        action.san,
        position.variant[position.index + 1],
      );
      if (same) {
        nextSelected = same;
        nextMoves = moves; // ревертим
        break;
      }

      // создаём ход
      const newMove: IPgnMove = {
        color: move.color === 'white' ? 'black' : 'white',
        moveFull: move.color === 'white' ? move.moveFull : move.moveFull + 1,
        san: action.san,
      };
      const newMoves = [newMove];
      const isMainVariant = !path[0]?.parent;
      let nextMoveIndex = index + 1;
      let nextMove = variant[nextMoveIndex];

      // ВАЖНО: если добавляется новый ход к последнему ходу основной ветки, и
      // запрещено менять основную ветку (как например при тарнсляциях), то
      // создаём новую ветку В САМОМ последнем ходе и дублируем этот ход в
      // самого себя, а уже после добавляем новый.
      // Такой компромис: вместо "1. g4 d5 2. Nc3" получим "1. g4 d5 (1... d5 2. Nc3)"
      if (!nextMove && action.readonlyMainVariant && isMainVariant) {
        nextMoveIndex = index;
        nextMove = move;
        newMoves.unshift({ ...move, alts: undefined });
      }

      if (!nextMove) {
        variant.push(newMove);
      } else {
        variant.splice(nextMoveIndex, 1, {
          ...nextMove,
          alts: nextMove.alts ? [...nextMove.alts, newMoves] : [newMoves],
        });
      }
      nextSelected = newMove;
      break;
    }
    // ВАЖНО: см. комментарий к DeleteMoveAction
    case 'delete':
      if (index === 0 && prevParent) {
        prevParent.alts = prevParent.alts?.filter((a) => a !== variant);
        if (prevParent.alts?.length === 0) prevParent.alts = undefined;
      } else {
        const nextVariant = variant.slice(0, index);
        variant.splice(0, variant.length, ...nextVariant);
      }
      nextSelected = position.prev ?? undefined;
      break;
    // ВАЖНО: см. комментарий к DeleteMoveAction
    case 'promote':
      if (move.alts?.[action.variant]) {
        const newTail = move.alts[action.variant]!;
        const newAlts = move.alts.filter((a) => a !== newTail);

        const newMove = newTail[0]!;

        const nextTail = [
          { ...newMove, alts: newAlts.length ? newAlts : undefined },
          ...newTail.slice(1),
        ];
        const nextVariant = variant.slice(0, index);
        nextVariant.push(...nextTail);
        variant.splice(0, variant.length, ...nextVariant);
      } else {
        console.error('PgnEditor: updateMove: invalid variant to promote', {
          action,
          move,
          moves,
        });
      }
      nextSelected = position.prev ?? undefined;
      break;
  }

  return { nextMoves, nextSelected };
}

// Навигация
//
// Правила:
// - first и prev по-умолчанию null, что позволяет перевести доску в состояние до 1 хода
// - next и last не дают выйти за пределы текущего варианта
// - prev и first на первом ходе варианта переключают выбор на родительский вариант
// - виртуальные ветки (когда части одного варианта отображаются как ветви
//   дерева вместе с настоящими вариантами) требуют дополнительной логики
export function getNavigationMoves(
  selected: IPgnMove | undefined,
  moves: IPgnMove[],
  allowInline = true,
) {
  // Defaults (если нет выбранного)

  let first: IPgnMove | undefined | null = undefined;
  let last: IPgnMove | undefined = moves[moves.length - 1];
  let next: IPgnMove | undefined = moves[0];
  let prev: IPgnMove | undefined | null = undefined;
  let playSequence: IPgnMove[] | undefined;

  // Посик (если есть выбранный)

  if (selected) {
    const position = findMovePosition(selected, moves);
    const path = position?.path;
    if (!path) {
      console.error(
        'PgnEditor: getNavigationMoves: current selected move not found in moves',
        { selected, moves },
      );
      return {};
    }

    playSequence = position.playSequence;

    next = position.next;
    prev = position.prev;

    const { variant } = position;
    const parentVariant =
      variant === moves ? undefined : (path[path.length - 2]?.branch ?? moves);

    first = variant[0] === selected ? parentVariant?.[0] : variant[0];
    if (first === moves[0] || variant === moves) first = null;

    last = variant[variant.length - 1];

    // Виртуальные ветки

    if (variant !== moves) {
      const getIsBranched = (m?: IPgnMove) =>
        m?.alts && (!allowInline || m.alts.length > 1);

      if (prev && getIsBranched(prev)) {
        first = prev;
      }

      if (getIsBranched(next)) {
        next = undefined;
        last = undefined;
      }

      const virtualLastIndex = variant.findIndex(
        (m, i) => i > position.index && getIsBranched(m),
      );
      if (virtualLastIndex !== -1) {
        last = variant[virtualLastIndex - 1];
      }
    }
  }

  // Нормализация

  if (first === moves[0]) first = null;
  if (last === selected) last = undefined;

  return { first, last, next, prev, playSequence };
}

function findMovePosition(
  move: IPgnMove,
  moves: IPgnMove[],
  prevMove: IPgnMove | null = null,
): MovePosition | undefined {
  for (let i = 0; i < moves.length; i++) {
    const m = moves[i]!;

    if (m === move) {
      return {
        variant: moves,
        index: i,
        next: moves[i + 1],
        prev: moves[i - 1] ?? prevMove,
        path: [],
        playSequence: moves.slice(0, i + 1),
      };
    }

    if (m.alts) {
      for (const a of m.alts) {
        const r = findMovePosition(move, a, moves[i - 1]);
        if (r) {
          return {
            ...r,
            playSequence: [...moves.slice(0, i), ...r.playSequence],
            path: r.path.length
              ? [{ parent: m, branch: a }, ...r.path]
              : [{ parent: m, branch: a }],
          };
        }
      }
    }
  }
}

export function getSimilarAlt(san: string, move?: IPgnMove) {
  if (!move) return undefined;
  if (move.san === san) return move;
  return move.alts?.find((a) => a[0]?.san === san)?.[0];
}
