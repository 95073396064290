import { useCallback, useState } from 'react';
import { useTournamentCardQuery } from '@features/tournament/api/hooks';
import { useTournamentGames } from '@features/tournament/hooks/useTournamentGames';
import { KnockoutRoundName } from '@features/tournament/ui/components/KnockoutRoundName';
import { getPresentRounds } from '@features/tournament/utils/tournamentHelpers';
import { useNavigate } from '@tanstack/react-router';
import { TournamentGames } from './TournamentGames';

/*
    ____,-------------------------------,____
    \   |            Виджет             |   /
    /___|-------------------------------|___\
*/

type Props = { clubId: string; tournamentId: string };

export function TournamentGamesWidget({ clubId, tournamentId }: Props) {
  const navigate = useNavigate();

  const { gamesHidden, onGamesToggle } = useGamesToggle();

  const { data: tournament } = useTournamentCardQuery(clubId, tournamentId, {
    staleTime: Infinity,
  });
  const isKnockout = tournament?.system === 'Knockout';

  const rounds = getPresentRounds(tournament?.rounds);
  const [round, setRound] = useState<number | null>(null);
  const selectedRound = round
    ? rounds?.find((r) => r.roundNumber === round)
    : rounds?.[rounds.length - 1];

  const roundRender = useCallback(
    (roundNumber: number) => {
      if (!rounds || !tournament) return roundNumber;

      const r = rounds.find((r) => r.roundNumber === roundNumber);
      if (!r) return roundNumber;

      if (isKnockout) {
        return (
          <KnockoutRoundName roundType="WinnerBranch" roundName={r.roundName} />
        );
      }

      return r.roundNumber;
    },
    [rounds, tournament, isKnockout],
  );

  const { games, cachedGames } = useTournamentGames(
    tournamentId,
    selectedRound,
    {
      enabled: !gamesHidden,
    },
  );

  const onGameClick = useCallback(
    (gameId: string) => {
      navigate({
        to: '/club/$clubId/tournament/$tournamentId/game/$gameId',
        params: { clubId, tournamentId, gameId },
      });
    },
    [navigate, clubId, tournamentId],
  );

  if (!selectedRound) return null;

  return (
    <TournamentGames
      key={selectedRound?.roundId}
      games={games ?? cachedGames}
      loading={!games}
      showToggle
      gamesHidden={gamesHidden}
      roundNumber={selectedRound?.roundNumber}
      roundsCount={rounds?.length}
      roundRender={roundRender}
      roundsAreStages={isKnockout}
      onGameClick={onGameClick}
      onGamesToggle={onGamesToggle}
      onRoundClick={setRound}
    />
  );
}

/*
    ____,-------------------------------,____
    \   |            Запчасти           |   /
    /___|-------------------------------|___\
*/

const LOCAL_STORAGE_KEY = 'hide_tournament_games';

function useGamesToggle() {
  const [gamesHidden, setGamesHidden] = useState(() => {
    return Boolean(localStorage.getItem(LOCAL_STORAGE_KEY));
  });
  const onGamesToggle = useCallback(
    (newValue: boolean) => {
      setGamesHidden(newValue);
      localStorage.setItem(LOCAL_STORAGE_KEY, newValue ? 'true' : '');
    },
    [setGamesHidden],
  );

  return { gamesHidden, onGamesToggle };
}
