import { IMoveDto, Pgn } from '@libs/chess/pgn';

export function mapPgnResultToText(result: Pgn<IMoveDto>['result']) {
  switch (result) {
    case '0-1':
      return 'Победа черных';
    case '1-0':
      return 'Победа белых';
    case '1/2-1/2':
      return 'Ничья';
    default:
      return null;
  }
}
