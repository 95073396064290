import { useEffect, useState } from 'react';
import { useTournamentGamesSocket } from '@features/shared/api/streams';
import { GameMovementRow } from '@features/shared/api/typings';
import {
  useTournamentCardQuery,
  useTournamentResultsGamesQuery,
  useTournamentResultsQuery,
} from '@features/tournament/api/hooks';
import { useGoToGame } from '@features/tournament/hooks/useGoToGame';
import { RoundMovement } from '@features/tournament/ui/components/RoundMovement/RoundMovement';
import { TournamentKnockoutResults } from '@features/tournament/ui/components/TournamentKnockoutResults';
import {
  getActiveRound,
  getPresentRounds,
} from '@features/tournament/utils/tournamentHelpers';
import { Pagination } from '@ui/components/Pagination/Pagination';
import styles from './RoundMovement.module.css';

type Props = {
  clubId: string;
  tournamentId: string;
};

export function RoundMovementWidget({ clubId, tournamentId }: Props) {
  const [roundNumber, setRoundNumber] = useState<number | null>(null);
  // Кэшируем строки, чтобы при переключении туров не моргать
  const [rows, setRows] = useState<GameMovementRow[]>([]);

  const { data: tournament } = useTournamentCardQuery(clubId, tournamentId);

  const isSeance = tournament?.system === 'Seance';
  const isKnockout = tournament?.system === 'Knockout';
  const isResultsQuery = isSeance || isKnockout;

  useEffect(() => {
    if (!tournament || roundNumber) return;
    const activeRound = getActiveRound(tournament?.rounds);
    setRoundNumber(activeRound);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournament]);

  const totalRoundsCount = getPresentRounds(tournament?.rounds)?.length || 1;

  // Сеансы используют ручку results
  const {
    data: movementData,
    isPending: isMovementPending,
    refetch: gamesRefetch,
  } = useTournamentResultsGamesQuery(
    clubId,
    tournamentId,
    roundNumber || 1,
    !!roundNumber && !isResultsQuery,
  );

  const {
    data: resultsData,
    isPending: isResultsPending,
    refetch: resultsRefetch,
  } = useTournamentResultsQuery(clubId, tournamentId, isResultsQuery);

  useEffect(() => {
    if (isKnockout) return;

    if (isSeance) {
      resultsData?.seanceResults?.rows &&
        setRows(resultsData?.seanceResults?.rows);
    } else {
      movementData?.rows && setRows(movementData?.rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsData, movementData, roundNumber]);

  // Отслеживание изменений игр
  const presentRounds = getPresentRounds(tournament?.rounds);
  const lastRound = presentRounds?.[presentRounds.length - 1];
  useTournamentGamesSocket({
    tournamentId,
    roundNumber: lastRound?.roundNumber ?? NaN,
    enabled: lastRound?.status === 'InProgress',
    cb: (e) => {
      const refetch = () => {
        if (isResultsQuery) {
          resultsRefetch();
        } else {
          gamesRefetch();
        }
      };

      if (e.eventType === 'GameChanged') {
        const result = e.currentState.result;
        if (
          result === 'Draw' ||
          result === 'BlackWin' ||
          result === 'WhiteWin'
        ) {
          refetch();
        }
      } else if (e.eventType === 'NewGameStarted') {
        refetch();
      }
    },
  });

  const goToGame = useGoToGame(clubId, tournamentId);

  if (isKnockout) {
    const results = resultsData?.knockOutResults;
    if (!results) return null;
    return (
      <TournamentKnockoutResults
        rounds={results.rounds}
        onGameClick={goToGame}
      />
    );
  }

  return (
    <>
      {totalRoundsCount > 1 && (
        <div className={styles.rounds}>
          Тур
          <Pagination
            pageTotal={totalRoundsCount}
            pageNum={roundNumber || 0}
            onPage={setRoundNumber}
          />
        </div>
      )}

      <RoundMovement
        isPending={isSeance ? isResultsPending : isMovementPending}
        rows={rows}
        total={resultsData?.seanceResults?.total}
        onGameClick={goToGame}
      />
    </>
  );
}
